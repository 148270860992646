import React from 'react';
import PropTypes from 'prop-types';
import signOnLogo from '../../assets/images/signOnLogoWhite.svg';

const WhiteLogo = ({ width, ...props }) => {
  return <img src={signOnLogo} alt="Sox Box" width={width || 250} {...props} />;
};

WhiteLogo.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
};

WhiteLogo.defaultProps = {
  width: null
};

export default WhiteLogo;
