import { get } from 'lodash/fp';
import styled from 'styled-components';
import Link from '@material-ui/core/Link';
import { withTheme } from '@material-ui/core';

export default withTheme(styled(Link)`
  &.MuiLink-root {
    cursor: pointer;
    padding-left: 12px;
    text-transform: uppercase;
    color: ${get('theme.palette.common.white')};
    :not(:last-of-type) {
      padding-right: 12px;
      border-right: 1px solid ${get('theme.palette.common.white')};
    }
    > svg {
      margin-right: 5px;
      vertical-align: top;
    }
  }
`);
