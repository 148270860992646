/* eslint-disable no-param-reassign */
import { action, thunk } from 'easy-peasy';
import { fetchPantry } from '../../services/fbm-api';

export default {
  data: null,
  loading: false,
  error: null,
  fetch: thunk(async (actions, pantryPath) => {
    try {
      actions.setLoading(true);
      const response = await fetchPantry(pantryPath);
      actions.set(response);
    } catch (error) {
      actions.setError(error);
    } finally {
      actions.setLoading(false);
    }
  }),
  set: action((state, response) => {
    state.data = response;
  }),
  clear: action(state => {
    state.data = null;
  }),
  setError: action((state, error) => {
    state.error = error;
  }),
  clearError: action(state => {
    state.error = null;
  }),
  setLoading: action((state, loading) => {
    state.loading = loading;
  })
};
