import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Badge, Tooltip, withTheme } from '@material-ui/core';
import ProfileIcon from '@material-ui/icons/AccountCircleOutlined';

const StyledBadge = withTheme(styled(Badge)`
  > .MuiBadge-badge {
    padding: 0 5px;
    &.MuiBadge-colorPrimary {
      background-color: ${get('theme.palette.success.main')};
    }
    &.MuiBadge-colorSecondary {
      font-weight: bold;
      color: ${get('theme.palette.common.white')};
    }
  }
`);

const ProfileIconWithVerifiedBadge = ({ verified, showBadge, ...props }) =>
  showBadge ? (
    <Tooltip
      placement="left-start"
      title={verified ? 'Verified Account' : 'Unverified Account'}
    >
      <StyledBadge
        badgeContent={verified ? '✔' : '!'}
        color={verified ? 'primary' : 'secondary'}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        {...props}
      >
        <ProfileIcon fontSize="small" />
        &nbsp;
      </StyledBadge>
    </Tooltip>
  ) : (
    <ProfileIcon fontSize="small" />
  );

ProfileIconWithVerifiedBadge.propTypes = {
  verified: PropTypes.bool.isRequired,
  showBadge: PropTypes.bool
};

ProfileIconWithVerifiedBadge.defaultProps = {
  showBadge: true
};

export default ProfileIconWithVerifiedBadge;
