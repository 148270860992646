import React from 'react';
import { Redirect } from 'react-router-dom';
import { getCurrentUser } from '../../provider/currentUser';
import {
  pantryBasePath as pantryDashboardPath,
  login as loginPath
} from '../../paths';

export default function CheckAuth() {
  const userSession = getCurrentUser();

  if (!userSession) {
    return <Redirect to={loginPath} />;
  }

  return <Redirect to={pantryDashboardPath} />;
}
