import React from 'react';
import { getOr } from 'lodash/fp';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useStoreState } from 'easy-peasy';
import awsS3Config from '../../config/aswS3';

const awsS3BaseUrl = getOr(
  'https://s3.us-east-1.amazonaws.com/',
  'baseUrl',
  awsS3Config
);

const Image = styled.img`
  display: inline-block;
`;

const composeImageUrl = (guid, bucket, image) =>
  `${awsS3BaseUrl.replace('//', `//${bucket}.`)}${guid}/${image}`;

const S3Image = ({ bucket, image, width, ...rest }) => {
  const guid = useStoreState(getOr('', 'pantry.data.guid'));
  return (
    <Image
      width={width}
      {...rest}
      src={image ? composeImageUrl(guid, bucket, image) : '/img/no-image.jpeg'}
    />
  );
};

S3Image.propTypes = {
  bucket: PropTypes.string,
  image: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

S3Image.defaultProps = {
  image: null,
  width: 'auto',
  bucket: awsS3Config.defaultBucket
};

export default S3Image;
