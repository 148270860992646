/* eslint-disable no-param-reassign */
import pages from './pages';
import components from './components';
import user from './user';
import userDetails from './userDetails';
import userReservations from './userReservations';
import userVisitedPantries from './userVisitedPantries';
import reservation from './reservation';
import reservationOrder from './reservationOrder';
import reservationPicklists from './reservationPicklists';
import pantry from './pantry';
import pantryPrograms from './pantryPrograms';
import programsOutreaches from './programsOutreaches';
import programsPicklists from './programsPicklists';
import notifications from './notifications';
import selectedEvent from './selectedEvent';
import inventory from './inventory';
import listeners from '../storeListeners';

export default {
  pages,
  components,
  user,
  userDetails,
  userReservations,
  userVisitedPantries,
  reservation,
  reservationOrder,
  reservationPicklists,
  pantry,
  pantryPrograms,
  programsOutreaches,
  programsPicklists,
  notifications,
  selectedEvent,
  inventory,
  ...listeners
};
