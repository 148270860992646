export const PENDING = 0;
export const ACTIVE = 1;
export const ON_CALL = 2;
export const COMPLETED = 3;
export const NO_SHOW_NO_CALL = 4;
export const NO_SHOW_AND_CALLED = 5;
export const ON_HOLD = 6;
export const REJECTED = 7;
export const CANCELED = 8;
export const CONFIRMED = 9;
