import React, { useEffect } from 'react';
import * as yup from 'yup';
import { has, get, getOr } from 'lodash/fp';
import { useForm } from 'react-hook-form';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from '../../provider/styles/form';
import Logo from '../../components/Logo';
import Copyright from '../../components/Copyright';
import { login as loginPath } from '../../paths';
import useCurrentUser from '../../hooks/useCurrentUser';
import useLocationQuery from '../../hooks/useLocationQuery';
import history from '../../history';
import { LOGIN, CONTACT } from '../../constants/UserEmailTypes';

const validationSchema = yup.object().shape({
  code: yup.string().required('Required field')
});

const RequestCodeMessage =
  'Email verification code sent. Please check your email.';

export default function EmailVerification() {
  const classes = useStyles();
  const query = useLocationQuery();

  const type = get('type', query);
  const verificationCode = getOr('', 'code', query);
  const userSession = useCurrentUser();
  const { data: user, loading, error } = useStoreState(get('user'));
  const userDetails = useStoreState(get('userDetails.data'));
  const setUser = useStoreActions(get('user.set'));
  const fetchUserDetails = useStoreActions(get('userDetails.fetch'));
  const { showError, showSuccess } = useStoreActions(get('notifications'));
  const {
    verifyEmail,
    requestEmailVerificationCode,
    clearError: clearUserError
  } = useStoreActions(get('user'));

  const { register, handleSubmit, errors } = useForm({
    validationSchema,
    defaultValues: { code: verificationCode }
  });

  const onSubmit = async ({ code }) => {
    verifyEmail({ userId: user.id, code, type });
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleResendCode = async () => {
    await requestEmailVerificationCode({ userId: user.id, type });
    showSuccess({ message: RequestCodeMessage });
  };

  const isError = field => has(field, errors);
  const getFieldError = field => {
    return getOr('', `${field}.message`, errors);
  };

  useEffect(() => {
    if (!userSession) {
      history.push(loginPath);
    } else if (!user) {
      setUser(userSession);
      fetchUserDetails(userSession.id);
    }
  }, [userSession, user]);

  useEffect(() => {
    if (userDetails && verificationCode && type) {
      const isVerifyLoginEmail =
        type === LOGIN && !get('verifiedAt', userSession);
      const isVerifyContactEmail =
        type === CONTACT && !get('verifiedAt', userDetails);

      if (isVerifyLoginEmail || isVerifyContactEmail) {
        verifyEmail({ userId: userSession.id, code: verificationCode, type });
      }
    }
  }, [userDetails, verificationCode, type]);

  useEffect(() => {
    if (error) {
      showError({ message: get('message', error) });
      clearUserError();
    }
  }, [error, showError, clearUserError]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Logo />
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h5" align="center">
          Email Verification
        </Typography>
        <form
          noValidate
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                variant="outlined"
                required
                fullWidth
                name="code"
                size="small"
                label="Enter Verification Code"
                disabled={loading}
                inputRef={register}
                error={isError('code')}
                helperText={getFieldError('code')}
              />
            </Grid>
            <Grid item xs={12}>
              <Box textAlign="center">
                <Button
                  color="primary"
                  onClick={handleResendCode}
                  disabled={loading}
                >
                  Request New Code
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
          >
            {(!loading && 'Submit') || (
              <CircularProgress size={24} color="inherit" />
            )}
          </Button>
          <Button fullWidth variant="outlined" onClick={handleBack}>
            Back
          </Button>
        </form>
        <Box mt={3}>
          <Copyright />
        </Box>
      </div>
    </Container>
  );
}
