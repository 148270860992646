import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useStoreState } from 'easy-peasy';
import { get, map } from 'lodash/fp';
import styled from 'styled-components';
import { FormControl, InputLabel, Select, withTheme } from '@material-ui/core';
import { parseInt } from '../../helpers/utils';

const Label = styled(InputLabel)`
  top: -8px;
  &.MuiInputLabel-shrink {
    visibility: hidden;
  }
`;

const TimeSlotDropdown = withTheme(styled(props => (
  <Select native {...props} />
))`
  border-radius: 16px;
  > select {
    border-radius: 16px;
    padding: 10px 26px 10px 12px;
    background-color: ${({ theme: { palette } }) => palette.common.white};
    &:focus {
      border-radius: 16px;
    }
  }
`);

function TimeSlotsSelection({
  outreachId,
  date,
  endTime,
  scheduleMinutesInterval,
  timeSlots,
  onSelect
}) {
  const [value, setValue] = useState('');
  const [currentDateTime, setCurrentDateTime] = useState(moment());
  const selectedTimeSlot = useStoreState(get('selectedEvent.timeSlot'));
  const selectedEvent = useStoreState(get('selectedEvent.event'));
  const selectedOutreachId = get('id', selectedEvent);

  useEffect(() => {
    if (selectedTimeSlot) {
      if (parseInt(selectedOutreachId) === outreachId) {
        setValue(selectedTimeSlot);
      } else {
        setValue('');
      }
    } else setValue('');
  }, [selectedTimeSlot, selectedOutreachId]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDateTime(moment());
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const isPastTimeSlot = (dateTimeSlot, dateTime) => {
    const dateTimeToCompare =
      scheduleMinutesInterval === 0 ? `${date} ${endTime}` : dateTimeSlot;

    return moment(dateTimeToCompare, 'YYYY-MM-DD LT').isBefore(dateTime);
  };

  return (
    <FormControl variant="outlined" style={{ width: '100%' }}>
      <Label htmlFor="request-time">Select Time Slot</Label>
      <TimeSlotDropdown
        native
        value={value}
        onChange={onSelect}
        inputProps={{ name: 'requestTime', id: 'request-time' }}
      >
        <option aria-label="None" value="" />
        {map(({ time, label, slots, reservedSlots }) => {
          const isPastTime = isPastTimeSlot(`${date} ${time}`, currentDateTime);
          return (
            <option
              value={time}
              key={`slot-${time}`}
              disabled={(slots > 0 && slots <= reservedSlots) || isPastTime}
            >
              {label}
              {isPastTime && ' [closed]'}
            </option>
          );
        }, timeSlots)}
      </TimeSlotDropdown>
    </FormControl>
  );
}

TimeSlotsSelection.propTypes = {
  outreachId: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  scheduleMinutesInterval: PropTypes.number.isRequired,
  timeSlots: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelect: PropTypes.func.isRequired
};

export default TimeSlotsSelection;
