import React from 'react';
import styled from 'styled-components';
import { Typography, withTheme } from '@material-ui/core';

export default withTheme(styled(props => (
  <Typography variant="h4" {...props} />
))`
  padding: 8px;
  margin: 10px auto 20px;
  min-width: 280px;
  font-weight: 600;
  border-radius: 6px;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme: { palette } }) => palette.common.white};
  background-color: ${({ theme: { palette } }) => palette.primary.light};
  > small {
    font-size: 20px;
    display: block;
  }
`);
