/* eslint-disable no-param-reassign */
import { action } from 'easy-peasy';

export default {
  showDialog: false,
  showLoginForm: false,
  showSignupForm: false,
  setShowDialog: action((state, showDialog) => {
    state.showDialog = showDialog;
  }),
  setShowLoginForm: action((state, showLoginForm) => {
    state.showLoginForm = showLoginForm;
  }),
  setShowSignupForm: action((state, showSignupForm) => {
    state.showSignupForm = showSignupForm;
  })
};
