/* eslint-disable no-param-reassign */
import { action, thunk } from 'easy-peasy';
import { fetchProgramPicklsits } from '../../services/fbm-api';

export default {
  data: null,
  meta: null,
  error: null,
  loading: false,
  fetch: thunk(async (actions, payload) => {
    try {
      actions.setLoading(true);
      const response = await fetchProgramPicklsits(payload);
      actions.set(response);
    } catch (error) {
      actions.setError(error);
    } finally {
      actions.setLoading(false);
    }
  }),
  set: action((state, payload) => {
    const { items, meta } = payload;
    state.data = items;
    state.meta = meta;
  }),
  clear: action(state => {
    state.data = null;
    state.meta = null;
  }),
  setError: action((state, error) => {
    state.error = error;
  }),
  setLoading: action((state, loading) => {
    state.loading = loading;
  })
};
