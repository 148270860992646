import React from 'react';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

export default withTheme(styled(props => (
  <Typography variant="h5" {...props} />
))`
  padding: 8px;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ theme: { palette } }) => palette.common.white};
  background-color: ${({ theme: { palette } }) => palette.primary.light};
  ${({ theme: { breakpoints } }) => `${breakpoints.down('sm')} {
    font-size: 18px;
    padding: 5px 0 8px;
  }`};
  > hr {
    top: 6px;
    width: 2px;
    height: 30px;
    margin: 0 8px;
    position: relative;
    display: inline-block;
    background-color: ${({ theme: { palette } }) => palette.common.white};
    ${({ theme: { breakpoints } }) => `${breakpoints.down('sm')} {
      top: 4px;
      height: 22px;
    }`};
  }
`);
