import React from 'react';
import { Redirect } from 'react-router-dom';
import { login as loginPath } from '../../paths';
import useLocationQuery from '../../hooks/useLocationQuery';

export default function EmailVerified() {
  const { email } = useLocationQuery();
  // eslint-disable-next-line no-undef
  localStorage.setItem('loginEmail', email);

  return <Redirect to={loginPath} />;
}
