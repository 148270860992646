import { get } from 'lodash/fp';

export default itemAvailability => {
  if (!itemAvailability) return null;
  const { item, location } = itemAvailability;
  const zoneId = get('zone.id', itemAvailability);
  return zoneId
    ? `${item.id}_${location.id}_${zoneId}`
    : `${item.id}_${location.id}`;
};
