export const getCurrentUser = () => {
  // eslint-disable-next-line no-undef
  const details = localStorage.getItem('currentUser');
  try {
    return JSON.parse(details);
  } catch (e) {
    return null;
  }
};

export const setCurrentUser = user => {
  // eslint-disable-next-line no-undef
  localStorage.setItem('currentUser', JSON.stringify(user));
};

export const clearCurrentUser = () => {
  // eslint-disable-next-line no-undef
  localStorage.removeItem('currentUser');
};
