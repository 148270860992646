import React from 'react';
import { CircularProgress, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

export default () => (
  <Container className={useStyles().paper}>
    <CircularProgress />
  </Container>
);
