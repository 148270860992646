import * as Status from './ReservationStatus';

export default {
  [Status.ON_HOLD]: 'Incomplete',
  [Status.PENDING]: 'Pending',
  [Status.ACTIVE]: 'Approved',
  [Status.CONFIRMED]: 'Confirmed',
  [Status.REJECTED]: 'Rejected',
  [Status.CANCELED]: 'Canceled',
  [Status.COMPLETED]: 'Completed'
};
