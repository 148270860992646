import { get } from 'lodash/fp';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';

export default withTheme(styled(Button)`
  &.MuiButton-root {
    border-radius: 16px;
    color: ${get('theme.palette.common.white')};
    border: 1px solid ${get('theme.palette.common.white')};
  }
`);
