import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Badge, Tooltip, withTheme } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/EmailOutlined';

const StyledBadge = withTheme(styled(Badge)`
  > .MuiBadge-badge {
    padding: 0 5px;
    &.MuiBadge-colorPrimary {
      background-color: ${get('theme.palette.success.main')};
    }
    &.MuiBadge-colorSecondary {
      font-weight: bold;
      color: ${get('theme.palette.common.white')};
    }
  }
`);

const EmailIconWithVerifiedBadge = ({ verified, ...props }) => (
  <Tooltip
    placement="left-start"
    title={verified ? 'Verified Email' : 'Unverified Email'}
  >
    <StyledBadge
      badgeContent={verified ? '✔' : '!'}
      color={verified ? 'primary' : 'secondary'}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      {...props}
    >
      <EmailIcon fontSize="small" />
      &nbsp;
    </StyledBadge>
  </Tooltip>
);

EmailIconWithVerifiedBadge.propTypes = {
  verified: PropTypes.bool.isRequired
};

export default EmailIconWithVerifiedBadge;
