import React from 'react';
import { Typography, withTheme } from '@material-ui/core';
import styled from 'styled-components';

export default withTheme(styled(props => (
  <Typography variant="h5" {...props} />
))`
  padding: 8px 10px;
  color: ${({ theme: { palette } }) => palette.common.white};
  background-color: ${({ theme: { palette } }) => palette.secondary.main};
  ${({ theme: { breakpoints } }) => `${breakpoints.down('sm')} {
      font-size: 18px;
      padding: 5px 10px;
    }`};
`);
