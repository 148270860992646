export const getAccessToken = () => {
  // eslint-disable-next-line no-undef
  return localStorage.getItem('accessToken');
};

export const setAccessToken = token => {
  // eslint-disable-next-line no-undef
  localStorage.setItem('accessToken', token);
};

export const clearAccessToken = () => {
  // eslint-disable-next-line no-undef
  localStorage.removeItem('accessToken');
};
