import React, { useState } from 'react';
import * as yup from 'yup';
import { has, getOr, get } from 'lodash/fp';
import { useForm } from 'react-hook-form';
import { useStoreActions } from 'easy-peasy';
import { Link as RouterLink } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from '../../provider/styles/form';
import Logo from '../../components/Logo';
import Copyright from '../../components/Copyright';
import { requestResetPassword } from '../../services/userAuth';
import history from '../../history';
import { resetPasswordVerification } from '../../paths';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Must be a valid email')
    .required('Required field')
});

export default function ForgotPassword() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm({ validationSchema });
  const { showError, showSuccess } = useStoreActions(get('notifications'));

  const onSubmit = async ({ email }) => {
    try {
      setIsLoading(true);
      await requestResetPassword(email);
      showSuccess({
        message: 'Password verification code has been sent to your email.'
      });
      history.push(encodeURI(`${resetPasswordVerification}?email=${email}`));
    } catch (error) {
      showError({ message: getOr('Invalid Email.', 'message', error) });
    } finally {
      setIsLoading(false);
    }
  };

  const isError = field => has(field, errors);
  const getFieldError = field => {
    return getOr('', `${field}.message`, errors);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Logo />
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h5" align="center">
          Forgot Password
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                variant="outlined"
                required
                fullWidth
                name="email"
                size="small"
                label="Email Address"
                autoComplete="email"
                disabled={isLoading}
                inputRef={register}
                error={isError('email')}
                helperText={getFieldError('email')}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isLoading}
          >
            {(!isLoading && 'Submit') || (
              <CircularProgress size={24} color="inherit" />
            )}
          </Button>
          <Box textAlign="right">
            <Link to="/login" variant="body2" component={RouterLink}>
              Login
            </Link>
          </Box>
        </form>
        <Box mt={3}>
          <Copyright />
        </Box>
      </div>
    </Container>
  );
}
