import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import PantryType from '../../interfaces/PantryType';
import pantryAddressLine from '../../helpers/pantryAddressLine';
import pagePathToUrl from '../../helpers/pagePathToUrl';
import { pantryBasePath } from '../../paths';
import history from '../../history';

const StyledCard = withTheme(styled(props => <Card {...props} />)`
  box-shadow: none;
  border: 1px solid ${({ theme: { palette } }) => palette.common.lightGray};
  > button > .MuiCardContent-root {
    box-shadow: none;
    padding: 10px;
    margin: 10px 10px 0;
    text-transform: uppercase;
    color: ${({ theme: { palette } }) => palette.common.white};
    border: 1px solid ${({ theme: { palette } }) => palette.common.lightGray};
    background-color: ${({ theme: { palette } }) => palette.primary.light};
    > h5 {
      font-weight: 600;
      ${({ theme: { breakpoints } }) => `${breakpoints.down('sm')} {
        font-size: 20px;
      }`};
    }
    > address {
      font-style: normal;
      color: ${({ theme: { palette } }) => palette.common.white};
      ${({ theme: { breakpoints } }) => `${breakpoints.down('sm')} {
        font-size: 12px;
      }`};
    }
  }
  .MuiCardActions-root > button:last-child {
    margin-left: auto;
  }
`);

function PantryCard({ data, onDelete }) {
  const { name, path } = data;
  const handleMakeReservation = () => {
    history.push(pagePathToUrl(pantryBasePath, { pantryPath: path }));
  };

  const handleDelete = () => {
    onDelete(data);
  };

  return (
    <StyledCard>
      <CardActionArea onClick={handleMakeReservation}>
        <CardContent>
          <Typography variant="h5">{name}</Typography>
          <Typography variant="body2" color="textSecondary" component="address">
            {pantryAddressLine(data)}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          color="primary"
          onClick={handleMakeReservation}
          startIcon={<AddIcon />}
        >
          Make a Reservation
        </Button>
        <IconButton onClick={handleDelete}>
          <DeleteIcon fontSize="small" color="error" />
        </IconButton>
      </CardActions>
    </StyledCard>
  );
}

PantryCard.propTypes = {
  data: PantryType.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default PantryCard;
