import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get, every } from 'lodash/fp';
import { withTheme } from '@material-ui/core/styles';
import { Box, Divider } from '@material-ui/core';
import { useStoreActions, useStoreState } from 'easy-peasy';
import LabelsByStatus from '../../constants/ReservationStatusLabels';
import pagePathToUrl from '../../helpers/pagePathToUrl';
import { parseInt } from '../../helpers/utils';
import EventType from '../../interfaces/EventType';
import history from '../../history';
import {
  reservationOrder as reservationOrderPath,
  reservationSummary as reservationSummaryPath
} from '../../paths';
import EventHeader from '../EventHeader';
import EventContent from '../EventContent';
import EventTimeSlots from '../EventTimeSlots';
import EventPrograms from '../EventPrograms';
import { ON_HOLD } from '../../constants/ReservationStatus';
import ReservationStatus from '../ReservationStatus';
import FullBackdrop from './FullBackdrop';

const EventBox = withTheme(styled(props => <Box {...props} />)`
  padding: 8px;
  border-radius: 6px;
  border: 2px solid ${({ theme: { palette } }) => palette.common.lightGray};
  background-color: ${({ theme: { palette } }) => palette.common.white};
  &.full > h5,
  &.reserved > h5 {
    background-color: #ccc;
  }
  &.selected {
    border-color: ${({ theme: { palette } }) => palette.secondary.main};
  }
  &.selected > h5 {
    background-color: ${({ theme: { palette } }) => palette.secondary.main};
  }
`);

function Event({ data, reserved }) {
  const { id, date, trackingMethods, schedule } = data;
  const outreachDate = moment(date);
  const { pantryPath } = useParams();
  const selectedEvent = useStoreState(get('selectedEvent.event'));
  const setSelectedEvent = useStoreActions(get('selectedEvent.set'));

  const handleClickReservation = () => {
    const redirectPath =
      reserved.status === ON_HOLD
        ? reservationOrderPath
        : reservationSummaryPath;
    history.push(pagePathToUrl(redirectPath, { id: reserved.id, pantryPath }));
  };

  const handleSelectTimeSlot = ({ target }) => {
    const { value: timeSlot } = target;
    setSelectedEvent({ event: data, timeSlot });
  };

  const isFull = useMemo(
    () =>
      every(
        ({ slots, reservedSlots }) => slots > 0 && slots === reservedSlots,
        schedule
      ),
    [schedule]
  );

  const fullClass = isFull ? 'full' : '';
  const reservedClass = reserved ? 'reserved' : '';
  const selectedClass =
    parseInt(get('id', selectedEvent)) === id ? 'selected' : '';

  return (
    <EventBox className={`${fullClass} ${reservedClass} ${selectedClass}`}>
      <EventHeader variant="h5">
        {outreachDate.format('MMM D, YYYY')}
        <Divider orientation="vertical" flexItem />
        {outreachDate.format('dddd')}
      </EventHeader>
      <EventContent>
        {reserved ? (
          <ReservationStatus
            style={{ width: '100%' }}
            status={reserved.status}
            label={`${LabelsByStatus[reserved.status]} - (Reserved)`}
            onClick={handleClickReservation}
          />
        ) : (
          <>
            {isFull && <FullBackdrop />}
            <EventTimeSlots
              outreachId={id}
              date={data.date}
              endTime={data.endTime}
              scheduleMinutesInterval={data.scheduleMinutesInterval}
              timeSlots={schedule}
              onSelect={handleSelectTimeSlot}
            />
          </>
        )}
        <EventPrograms data={trackingMethods} />
      </EventContent>
    </EventBox>
  );
}

Event.propTypes = {
  data: EventType.isRequired,
  reserved: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.number.isRequired
  })
};

Event.defaultProps = {
  reserved: null
};

export default Event;
