import PropTypes from 'prop-types';
import PantryType from './PantryType';
import NumberOrStringType from './NumberOrStringType';

const MetaProgramType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
});

export default PropTypes.shape({
  id: PropTypes.number.isRequired,
  pantrySiteId: PropTypes.number.isRequired,
  reservationId: PropTypes.number.isRequired,
  requestTime: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
  meta: PropTypes.shape({
    householdTotal: PropTypes.number.isRequired,
    outreachId: NumberOrStringType.isRequired,
    programs: PropTypes.arrayOf(MetaProgramType).isRequired
  }).isRequired,
  pantrySite: PantryType.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string
});
