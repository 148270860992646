/* eslint-disable no-param-reassign */
import { action, thunk } from 'easy-peasy';
import { fetchUser, updateUser } from '../../services/fbm-api';

export default {
  data: null,
  loading: false,
  error: null,
  fetch: thunk(async (actions, id) => {
    try {
      actions.setLoading(true);
      const response = await fetchUser(id);
      actions.set(response);
    } catch (error) {
      actions.setError(error);
    } finally {
      actions.setLoading(false);
    }
  }),
  update: thunk(async (actions, payload) => {
    try {
      actions.setLoading(true);
      const response = await updateUser(payload);
      actions.set(response);
      return response;
    } catch (error) {
      actions.setError(error);
      return error;
    } finally {
      actions.setLoading(false);
    }
  }),
  set: action((state, payload) => {
    state.data = payload;
  }),
  setLoading: action((state, loading) => {
    state.loading = loading;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  })
};
