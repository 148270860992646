import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  toolbar: {
    minHeight: 100,
    [theme.breakpoints.up('lg')]: {
      width: 1240,
      margin: '0 auto'
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      fontSize: '75%',
      minHeight: 60
    }
  },
  heading: {
    marginBottom: 8,
    [theme.breakpoints.up('md')]: {
      width: '50vw'
    },
    [theme.breakpoints.up('lg')]: {
      width: 760
    },
    [theme.breakpoints.down('sm')]: {
      width: '75vw',
      marginBottom: 3,
      fontSize: '1.1rem'
    },
    [theme.breakpoints.down('xs')]: {
      width: '62vw'
    }
  },
  subHeaderIcon: {
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    }
  },
  subHeaderDetails: {
    marginLeft: 25,
    [theme.breakpoints.down('xs')]: {
      width: '56vw',
      marginLeft: 20
    }
  }
}));
