import React from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  chip: {
    fontSize: 16,
    fontWeight: 600,
    padding: '18px 5px',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      padding: 0
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  }
}));

export default props => {
  const styles = useStyles();
  return <Chip color="primary" className={styles.chip} {...props} />;
};
