import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { get, getOr, isEmpty } from 'lodash/fp';
import { useParams } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  withTheme
} from '@material-ui/core';
import ContinueIcon from '@material-ui/icons/NavigateNext';
import history from '../../../history';
import pagePathToUrl from '../../../helpers/pagePathToUrl';
import {
  guestDashboard as guestDashboardPath,
  guestEditProfile as guestProfilePath,
  reservationOrder as reservationOrderPath
} from '../../../paths';
import Layout from '../../../components/Layout';
import PageLoading from '../../../components/PageLoading';
import PantryDetails from '../../../components/PantryDetails';
import StepHeader from '../../../components/ReservationStepHeader';
import ProgramsSelection from './ProgramsSelection';
import ProgramsOutreaches from './ProgramsOutreaches';
import ReservationErrorDialog from '../../../components/ReservationErrorDialog';

const ContentBox = withTheme(styled(Box)`
  > h6 {
    margin: 20px 0;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    ${({ theme }) => `${theme.breakpoints.down('sm')} {
      font-size: 95%;
      margin: 10px 0 0;
    }`};
  }
  > .MuiChip-root {
    margin: 20px 0;
    ${({ theme }) => `${theme.breakpoints.down('sm')} {
      margin-bottom: 10px;
    }`};
  }
  > .MuiGrid-root {
    margin-bottom: 20px;
    ${({ theme }) => `${theme.breakpoints.down('sm')} {
      font-size: 95%;
      margin-bottom: 0;
    }`};
  }
  > .MuiGrid-root > .MuiGrid-item {
    ${({ theme }) => `${theme.breakpoints.down('sm')} {
      padding-bottom: 0;
    }`};
  }
`);

export default function Pantry() {
  const unqualifiedGuestForProgramsError =
    "We're sorry, a program has changed, you need to start again.";
  const { pantryPath } = useParams();
  const [submitReservation, setSubmitReservation] = useState(false);
  const user = useStoreState(get('user.data'));
  const userDetails = useStoreState(get('userDetails.data'));
  const {
    data: reservation,
    error: reservationError,
    loading: loadingReservation
  } = useStoreState(get('reservation'));

  const {
    data: pantry,
    error: pantryError,
    loading: loadingPantry
  } = useStoreState(get('pantry'));

  const pantryPrograms = useStoreState(get('pantryPrograms.data'));
  const loadingPrograms = useStoreState(get('pantryPrograms.loading'));
  const pantryProgramsError = useStoreState(get('pantryPrograms.error'));
  const loadingOutreaches = useStoreState(get('programsOutreaches.loading'));
  const outreachesError = useStoreState(get('programsOutreaches.error'));
  const reservedEventsById = useStoreState(state =>
    state.userReservations.reservationsByOutreachIdForPantry(get('id', pantry))
  );
  const selectedEvent = useStoreState(get('selectedEvent.event'));

  const getSelectedProgramIdsForOutreach = useStoreState(
    get('programsOutreaches.getSelectedProgramIdsForOutreach')
  );

  const fetchPantry = useStoreActions(get('pantry.fetch'));
  const clearPantry = useStoreActions(get('pantry.clear'));
  const clearPantryError = useStoreActions(get('pantry.clearError'));
  const showError = useStoreActions(get('notifications.showError'));
  const setReservation = useStoreActions(get('reservation.set'));
  const createReservation = useStoreActions(get('reservation.create'));

  const clearReservationError = useStoreActions(get('reservation.clearError'));
  const fetchPantryPrograms = useStoreActions(get('pantryPrograms.fetch'));
  const fetchOutreaches = useStoreActions(get('programsOutreaches.fetch'));
  const clearOutreaches = useStoreActions(get('programsOutreaches.clear'));
  const clearOutreachesError = useStoreActions(
    get('programsOutreaches.clearError')
  );
  const clearReservation = useStoreActions(get('reservation.clear'));
  const clearPantryPrograms = useStoreActions(get('pantryPrograms.clear'));
  const clearPantryProgramsError = useStoreActions(
    get('pantryPrograms.clearError')
  );
  const fetchUserReservations = useStoreActions(get('userReservations.fetch'));
  const {
    setShowLoginForm,
    setShowSignupForm,
    setShowDialog: showLoginSignupDialog
  } = useStoreActions(get('components.loginSignupDialog'));

  const selectedPrograms = get('programIds', reservation);
  const requestTime = getOr('', 'requestTime', reservation);

  const [errorMessage, setErrorMessage] = useState(null);
  const [showReservationErrorModal, setShowReservationErrorModal] = useState(
    false
  );

  const hasNameDetails = !!(
    get('firstName', userDetails) && get('lastName', userDetails)
  );

  const handleCloseReservationError = () => {
    clearOutreaches();
    setShowReservationErrorModal(false);
    setReservation({ requestTime: '', outreachId: '', programIds: [] });
  };

  const handleSelectProgram = programIds => {
    clearOutreaches();
    if (!isEmpty(programIds)) {
      setReservation({ programIds });
      fetchOutreaches({ pantryPath, programIds });
    } else {
      setReservation({ requestTime: '', outreachId: '', programIds });
    }
  };

  const handleContinue = () => {
    const { date, endTime, scheduleMinutesInterval } = selectedEvent;
    const dateTimeToCompare =
      scheduleMinutesInterval === 0 ? `${date} ${endTime}` : requestTime;
    const isPastTime = moment(dateTimeToCompare, 'YYYY-MM-DD LT').isBefore(
      moment()
    );

    if (isPastTime) {
      showError({
        message:
          'Selected time slot has already closed. Please select another time slot.'
      });
      return;
    }

    if (!userDetails) {
      showLoginSignupDialog(true);
      setShowLoginForm(true);
      setShowSignupForm(true);
    }
    setSubmitReservation(true);
  };

  useEffect(() => {
    fetchPantry(pantryPath);
    return () => {
      clearPantry();
      clearOutreaches();
      clearPantryPrograms();
      clearReservation();
    };
  }, []);

  useEffect(() => {
    if (pantry) {
      if (pantry.isExpiredClient) {
        showError({ message: `"${pantry.name}" Portal has been disabled.` });
        history.push(guestDashboardPath);
      } else fetchPantryPrograms(pantryPath);
    }
  }, [pantry, showError, fetchPantryPrograms, pantryPath]);

  useEffect(() => {
    if (pantryError) {
      showError({ message: get('message', pantryError) });
      clearPantryError();
      history.push(guestDashboardPath);
    }
  }, [pantryError]);

  useEffect(() => {
    const userId = get('id', user);
    if (userId) {
      fetchUserReservations(userId);
    }
  }, [user]);

  useEffect(() => {
    if (!userDetails) return;

    const { id: userId, email, firstName, lastName, otherInfo } = userDetails;
    setReservation({
      ...otherInfo,
      userId,
      email,
      firstName,
      lastName,
      householdTotal: getOr(1, 'householdTotal', otherInfo)
    });
  }, [userDetails]);

  useEffect(() => {
    if (!reservation) return;

    const { id = null, userId, programIds, outreachId } = reservation;
    const hasMinDetails = !!(userId && programIds && requestTime);

    if (id) {
      const redirectUrl = hasNameDetails
        ? pagePathToUrl(reservationOrderPath, { pantryPath, id })
        : `${guestProfilePath}?pantryPath=${pantryPath}&reservationId=${id}`;
      history.push(redirectUrl);
    } else if (hasMinDetails && submitReservation) {
      const body = {
        ...reservation,
        programIds: getSelectedProgramIdsForOutreach(outreachId)
      };
      createReservation({ pantryPath, body });
      setSubmitReservation(false);
    }
  }, [pantryPath, reservation, hasNameDetails, submitReservation]);

  // Handle reservation creation error
  useEffect(() => {
    if (reservationError) {
      if (reservationError.message === unqualifiedGuestForProgramsError) {
        setErrorMessage(reservationError.message);
        setShowReservationErrorModal(true);
      } else {
        showError({ message: reservationError.message });
      }
      setSubmitReservation(false);
      clearReservationError();
    }
  }, [
    reservationError,
    showError,
    clearReservationError,
    setSubmitReservation,
    setShowReservationErrorModal,
    setErrorMessage
  ]);

  useEffect(() => {
    if (pantryProgramsError) {
      showError({ message: pantryProgramsError.message });
      clearPantryProgramsError();
    }
  }, [pantryProgramsError]);

  useEffect(() => {
    if (outreachesError) {
      showError({ message: outreachesError.message });
      clearOutreachesError();
    }
  }, [outreachesError]);

  return (
    <Layout>
      {loadingPantry || loadingPrograms ? (
        <PageLoading />
      ) : (
        <>
          {pantry && <PantryDetails pantry={pantry} />}
          <ContentBox>
            <Typography variant="h6">Available Reservation:</Typography>
            <StepHeader label="Step 1: Select Help Desired" />
            <ProgramsSelection
              selected={selectedPrograms}
              disabled={loadingOutreaches}
              programs={pantryPrograms || []}
              onSelect={handleSelectProgram}
            />
            {!isEmpty(selectedPrograms) && (
              <>
                <StepHeader label="Step 2: Select time slot to reserve" />
                <ProgramsOutreaches
                  programIds={selectedPrograms}
                  reservedEventsById={reservedEventsById}
                />
              </>
            )}
            {!isEmpty(selectedPrograms) && requestTime && (
              <Box textAlign="right">
                <Button
                  style={{ marginLeft: 10 }}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleContinue}
                  endIcon={<ContinueIcon />}
                  disabled={loadingReservation}
                >
                  {loadingReservation ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Continue'
                  )}
                </Button>
              </Box>
            )}

            <ReservationErrorDialog
              message={errorMessage}
              open={showReservationErrorModal}
              onClose={handleCloseReservationError}
            />
          </ContentBox>
        </>
      )}
    </Layout>
  );
}
