import {
  registerUser,
  loginUser,
  requestUserEmailVerificationCode,
  verifyUserEmail,
  requestUserResetPassword,
  verifyUserResetPassword
} from '../fbm-api';
import { setAccessToken, clearAccessToken } from '../../provider/accessToken';
import { setCurrentUser, clearCurrentUser } from '../../provider/currentUser';

export async function signUp(email, password) {
  const user = await registerUser({ email, password });
  setCurrentUser(user);

  return user;
}

export async function logIn(email, password) {
  const { accessToken, user } = await loginUser({ email, password });
  setAccessToken(accessToken);
  setCurrentUser(user);

  return user;
}

export function logOut() {
  clearAccessToken();
  clearCurrentUser();
}

export function requestEmailVerificationCode(userId) {
  return requestUserEmailVerificationCode(userId);
}

export function verifyEmail(userId, code) {
  return verifyUserEmail({ userId, code });
}

export async function requestResetPassword(email) {
  const response = await requestUserResetPassword(email);

  return response;
}

export async function verifyResetPassword(email, code, password) {
  const response = await verifyUserResetPassword({ email, code, password });

  return response;
}
