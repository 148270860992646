import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash/fp';
import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';

const Notes = styled(Typography)(({ theme }) => ({
  marginTop: 8,
  marginBottom: 8,
  fontStyle: 'italic',
  color: theme.palette.common.gray
}));

const HeaderCell = styled(TableCell)(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.palette.common.white,
  background: theme.palette.primary.light
}));

const ItemNameCell = styled(TableCell)(({ theme }) => ({
  textTransform: 'uppercase',
  borderRight: `1px solid ${theme.palette.common.lightGray}`
}));

function OrderedList({ items, picklistName, notes }) {
  const dataRows = useMemo(
    () =>
      map(
        ({ id, quantity, itemSnapshot }) => ({
          id,
          quantity,
          name: itemSnapshot.name
        }),
        items
      ),
    [items]
  );

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <HeaderCell align="center" colSpan={2}>
                <strong>{picklistName}</strong>
              </HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRows.map(({ id, name, quantity }) => (
              <TableRow key={`item-${id}`}>
                <ItemNameCell scope="row">{name}</ItemNameCell>
                <TableCell style={{ width: 60 }} align="center">
                  {quantity}
                </TableCell>
              </TableRow>
            ))}
            {!!notes && (
              <TableRow>
                <TableCell colSpan={2}>
                  <Notes variant="body2" component="p">
                    Notes: <br /> {notes}
                  </Notes>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

OrderedList.propTypes = {
  picklistName: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  notes: PropTypes.string.isRequired
};

export default OrderedList;
