import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Barcode from 'react-barcode';
import parse from 'html-react-parser';
import { get, getOr, has, flow, map, join, isEmpty, includes } from 'lodash/fp';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Redirect, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Layout from '../../components/Layout';
import DangerButton from '../../components/DangerButton';
import PageLoading from '../../components/PageLoading';
import GrayCaption from '../../components/GrayCaption';
import Heading from './Heading';
import {
  login as loginPath,
  guestEditProfile as guestEditProfilePath,
  guestDashboard as guestDashboardPath,
  reservationOrder as reservationOrderPath,
  pantryBasePath
} from '../../paths';
import pagePathToUrl from '../../helpers/pagePathToUrl';
import useCurrentUser from '../../hooks/useCurrentUser';
import * as ReservationStatus from '../../constants/ReservationStatus';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import ReservationStatusChip from '../../components/ReservationStatus';
import history from '../../history';
import OrderedList from '../../components/OrderedList';
import ContactDetailsDialog from '../../components/ContactDetailsDialog';

const STATUS_ACTIONS = { PENDING: 'pending', CANCEL: 'cancel' };

const AllowedCancelStatus = [
  ReservationStatus.PENDING,
  ReservationStatus.ACTIVE,
  ReservationStatus.CONFIRMED
];

const AllowedDeleteStatus = [
  ReservationStatus.ON_HOLD,
  ReservationStatus.REJECTED,
  ReservationStatus.CANCELED
];

const AllowedManageOrdersStatus = [
  ReservationStatus.ON_HOLD,
  ReservationStatus.PENDING
];

const StatusUpdateMessages = {
  [ReservationStatus.ACTIVE]: 'Reservation Approved.',
  [ReservationStatus.PENDING]: 'Reservation Pending Awaiting Approval.',
  [ReservationStatus.CANCELED]: 'Reservation Cancelled.'
};

export default function ReservationSummary() {
  const { pantryPath, id } = useParams();
  const currentUser = useCurrentUser();
  const [currentDateTime, setCurrentDateTime] = useState(moment());
  const [postDeletePath, setPostDeletePath] = useState(guestDashboardPath);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showContactUs, setShowContactUs] = useState(false);
  const userDetails = useStoreState(get('userDetails.data'));
  const {
    loading,
    deleting,
    deleted,
    updatedStatus,
    updatingStatus,
    deleteError,
    updateStatusError,
    data: reservation,
    error: fetchError
  } = useStoreState(get('reservation'));
  const status = get('status', reservation);
  const orders = getOr([], 'orders', reservation);
  const { data: pantry, loading: loadingPantry } = useStoreState(get('pantry'));
  const { showError, showInfo, showSuccess } = useStoreActions(
    get('notifications')
  );
  const fetchPantry = useStoreActions(get('pantry.fetch'));
  const {
    updateStatus,
    clearUpdateStatusError,
    setDeleted,
    fetch: fetchReservation,
    clear: clearReservation,
    delete: deleteReservation,
    clearError
  } = useStoreActions(get('reservation'));

  const code = get('reservation.code', reservation);
  const isLoading = !has('id', reservation) || loading || loadingPantry;
  const requestTime = reservation && moment(reservation.requestTime);
  const event = get('reservation.outreach', reservation);
  const eventName = get('name', event);
  const eventDate = get('date', event);
  const eventEndTime = get('endTime', event);
  const createdAt = reservation && moment(get('createdAt', reservation));
  const isAllowedManageOrders = includes(status, AllowedManageOrdersStatus);

  const dateTimeToCompare =
    get('scheduleMinutesInterval', event) === 0
      ? moment(`${eventDate} ${eventEndTime}`)
      : requestTime;
  const isPastTimeSlot =
    dateTimeToCompare && dateTimeToCompare.isBefore(currentDateTime);

  const handleOnAgree = async () => {
    if (dateTimeToCompare.isBefore(moment())) {
      showError({
        message: "We're sorry! This reservation time slot has already closed."
      });
      return;
    }
    const statusAction = STATUS_ACTIONS.PENDING;
    updateStatus({ id, pantryPath, statusAction });
  };

  const handleConfirmCancel = async () => {
    const statusAction = STATUS_ACTIONS.CANCEL;
    updateStatus({ id, pantryPath, statusAction });
    setShowCancelConfirmation(false);
  };

  const handleConfirmDelete = async () => {
    if (isPastTimeSlot) {
      setPostDeletePath(pagePathToUrl(pantryBasePath, { pantryPath }));
    }
    const statusAction = STATUS_ACTIONS.CANCEL;
    await deleteReservation({ id, pantryPath, statusAction });
  };

  const handleManageOrders = () => {
    history.push(pagePathToUrl(reservationOrderPath, { pantryPath, id }));
  };

  const handleShowContactUs = () => {
    setShowContactUs(true);
  };

  const handleShowCancelConfirmation = () => {
    setShowCancelConfirmation(true);
  };

  const handleShowDeleteConfirmation = () => {
    setShowDeleteConfirmation(true);
  };

  const handleHideContactUs = () => {
    setShowContactUs(false);
  };

  const handleHideCancelConfirmation = () => {
    setShowCancelConfirmation(false);
  };

  const handleHideDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDateTime(moment());
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!pantry) fetchPantry(pantryPath);
  }, [pantry, pantryPath, fetchPantry]);

  useEffect(() => {
    fetchReservation({ pantryPath, id });
    return () => {
      clearReservation();
    };
  }, [fetchReservation, clearReservation, pantryPath, id]);

  useEffect(() => {
    if (get('code', fetchError) === 404) {
      showError({ message: 'Reservation not found.' });
      history.push(guestDashboardPath);
    }
    return () => {
      clearError();
    };
  }, [fetchError, clearError]);

  useEffect(() => {
    if (updatedStatus) {
      showSuccess({ message: StatusUpdateMessages[status] });
    } else if (updateStatusError) {
      showError({ message: updateStatusError.message });
    }
    clearUpdateStatusError();
  }, [
    status,
    updatedStatus,
    updateStatusError,
    clearUpdateStatusError,
    showError,
    showSuccess
  ]);

  useEffect(() => {
    if (!isEmpty(deleteError)) {
      showError({ message: deleteError.message });
      setShowDeleteConfirmation(false);
    } else if (deleted) {
      showSuccess({ message: 'Reservation Deleted.' });
      history.push(postDeletePath);
    }
    return () => {
      setDeleted(false);
    };
  }, [deleted, showError, deleteError]);

  if (!currentUser) {
    return <Redirect to={loginPath} />;
  }

  if (userDetails && !get('firstName', userDetails)) {
    showInfo({
      message: 'Please complete your profile details to complete reservation.'
    });
    const guestProfileUrl = `${guestEditProfilePath}?pantryPath=${pantryPath}&reservationId=${id}`;
    return <Redirect to={guestProfileUrl} />;
  }

  return (
    <Layout>
      {isLoading ? (
        <PageLoading />
      ) : (
        <Box mx="auto">
          {status === ReservationStatus.ON_HOLD && (
            <Box mb={2} mt={1}>
              {isPastTimeSlot ? (
                <Alert severity="error">
                  <AlertTitle>
                    We&apos;re sorry! This reservation time slot has already
                    closed.
                  </AlertTitle>
                  Delete this reservation and choose a different time.
                </Alert>
              ) : (
                <Alert severity="info">
                  <AlertTitle>Review and complete your reservation.</AlertTitle>
                  To complete your reservation you MUST click &quot;Agree &amp;
                  Confirm Reservation&quot; below.
                </Alert>
              )}
            </Box>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box textAlign={{ xs: 'center', sm: 'left' }}>
                <Heading>
                  {requestTime.format('MMM D, YYYY')}
                  <small>{requestTime.format('dddd LT')}</small>
                </Heading>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box textAlign={{ xs: 'center', sm: 'right' }}>
                {code && (
                  <Barcode value={code.toString()} height={60} width={3} />
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box textAlign={{ xs: 'center', sm: 'left' }} mb={2}>
                <Typography gutterBottom>
                  Chosen Help Desired: &nbsp;
                  <strong>
                    {flow(
                      get('meta.programs'),
                      map('name'),
                      join(', '),
                      parse
                    )(reservation)}
                  </strong>
                </Typography>
                {eventName && (
                  <Typography gutterBottom>
                    Event: &nbsp;
                    <strong>{parse(eventName)}</strong>
                  </Typography>
                )}
                <GrayCaption display="block">
                  Date Created: {createdAt.format('MM/DD/YYYY')}
                </GrayCaption>
              </Box>
            </Grid>
            {!isEmpty(orders) && (
              <Grid item xs={12}>
                <Box textAlign={{ xs: 'center', sm: 'left' }}>
                  <Typography variant="h6">Ordered Items:</Typography>
                </Box>
              </Grid>
            )}
            {map(
              each => (
                <Grid item xs={12} sm={4} key={`order-${each.id}`}>
                  <OrderedList
                    picklistName={each.picklistSnapshot.name}
                    items={each.items}
                    notes={each.notes}
                  />
                </Grid>
              ),
              orders
            )}
          </Grid>
          <Box mt={3} textAlign={{ xs: 'center', sm: 'left' }}>
            {status !== ReservationStatus.ON_HOLD && (
              <ReservationStatusChip status={status} style={{ padding: 15 }} />
            )}
          </Box>
          {!isEmpty(orders) && !isAllowedManageOrders && (
            <Box mt={2}>
              <Alert severity="info">
                <AlertTitle>Manage Orders</AlertTitle>
                Editing your order is only available while in PENDING status. In
                case you wanted some changes,
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={handleShowContactUs}
                  style={{ textTransform: 'lowercase', border: 'none' }}
                >
                  contact your agency
                </Button>
                and ask them to set your order to PENDING or edit the order for
                you.
              </Alert>
            </Box>
          )}
          <Box mt={{ xs: 6, sm: 3 }} textAlign={{ xs: 'center', sm: 'right' }}>
            {includes(status, AllowedCancelStatus) && (
              <DangerButton
                size="small"
                variant="contained"
                onClick={handleShowCancelConfirmation}
                disabled={updatingStatus}
                startIcon={<CancelIcon />}
                style={{ margin: 5 }}
              >
                {updatingStatus ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Cancel Reservation'
                )}
              </DangerButton>
            )}
            {includes(status, AllowedDeleteStatus) && (
              <DangerButton
                size="small"
                variant="contained"
                onClick={handleShowDeleteConfirmation}
                disabled={updatingStatus || deleting}
                startIcon={<DeleteIcon />}
                style={{ margin: 5 }}
              >
                Delete Reservation
              </DangerButton>
            )}
            {isAllowedManageOrders && (
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={handleManageOrders}
                disabled={updatingStatus}
                startIcon={<EditIcon />}
                style={{ margin: 5 }}
              >
                {updatingStatus ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Manage Orders'
                )}
              </Button>
            )}
            {!isPastTimeSlot && status === ReservationStatus.ON_HOLD && (
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={handleOnAgree}
                disabled={updatingStatus}
                startIcon={<ThumbUpAltIcon />}
                style={{ margin: 5 }}
              >
                {updatingStatus ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Agree & Confirm Reservation'
                )}
              </Button>
            )}
          </Box>
        </Box>
      )}
      <ConfirmationDialog
        key="confirm-cancel"
        title="Confirm Cancel"
        message="Are you sure you want to CANCEL?"
        open={showCancelConfirmation}
        onConfirm={handleConfirmCancel}
        onClose={handleHideCancelConfirmation}
      />

      <ConfirmationDialog
        key="confirm-delete"
        title="Confirm Delete"
        message="Are you sure you want to DELETE?"
        open={showDeleteConfirmation}
        onConfirm={handleConfirmDelete}
        onClose={handleHideDeleteConfirmation}
      />

      <ContactDetailsDialog
        open={showContactUs}
        onClose={handleHideContactUs}
      />
    </Layout>
  );
}
