import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Divider, Grid, Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import {
  findIndex,
  flow,
  get,
  getOr,
  keyBy,
  map,
  filter,
  reject,
  set,
  sumBy,
  isNull
} from 'lodash/fp';
import { useStoreState } from 'easy-peasy';
import { parseInt } from '../../helpers/utils';
import guestQuantityLimit from '../../helpers/guestQuantityLimit';
import PicklistItem from '../PicklistItem';
import PicklistCategoryType from '../../interfaces/PicklistCategoryType';
import PicklistItemType from '../../interfaces/PicklistItemType';

const CategoryName = withTheme(styled(props => (
  <Typography {...props} variant="h5" />
))`
  padding-bottom: 5px;
  font-size: 1.6em;
  text-transform: uppercase;
`);

const toOrderItemUniqKey = (picklistId, picklistCategoryId, itemId) =>
  `${picklistId}_${picklistCategoryId}_${itemId}`;

const toOrderItemsByUniqKey = (picklistId, orderItems) =>
  keyBy(
    ({ itemId, picklistCategoryId }) =>
      toOrderItemUniqKey(picklistId, picklistCategoryId, itemId),
    orderItems
  );

const getCategoryLimit = (guestInfo, limitRules, picklistLimit) => {
  const hasLimit = !isNull(limitRules);
  const categoryLimit = guestQuantityLimit(guestInfo, limitRules);

  if (hasLimit && picklistLimit > 0 && categoryLimit > picklistLimit)
    return picklistLimit;

  return categoryLimit;
};

const PicklistCategory = ({
  data: { id, name, limitRules },
  picklistId,
  items,
  orderItems,
  picklistLimit,
  picklistLimitReached,
  onChangeOrderItems
}) => {
  const guestInfo = useStoreState(get('userDetails.data.otherInfo'));
  const orderItemsByKey = toOrderItemsByUniqKey(picklistId, orderItems);
  const quantityLimit = getCategoryLimit(guestInfo, limitRules, picklistLimit);

  const addedQuantity = useMemo(
    () =>
      flow(filter({ picklistCategoryId: id }), sumBy('quantity'))(orderItems),
    [orderItems]
  );

  const limitReached = quantityLimit > 0 && addedQuantity >= quantityLimit;

  const handleChangeOrderItem = orderItem => {
    const { itemId, picklistCategoryId, quantity } = orderItem;
    const itemIndex = findIndex({ itemId, picklistCategoryId }, orderItems);
    const itemExists = itemIndex >= 0;

    if (!itemExists) {
      onChangeOrderItems([...orderItems, orderItem]);
    } else if (quantity < 1 && !get('id', orderItem))
      onChangeOrderItems(reject({ itemId, picklistCategoryId }, orderItems));
    else onChangeOrderItems(set(itemIndex, orderItem, orderItems));
  };

  return (
    <>
      <Grid container key={`category-${id}`} spacing={3}>
        <Grid item xs={12} sm={3}>
          <CategoryName>
            {name}
            {quantityLimit > 0 && ` (${quantityLimit - addedQuantity})`}
          </CategoryName>
        </Grid>
        <Grid item xs={12} sm={9}>
          {map(item => {
            const { inventorySnapshot = null } = item;
            const itemId = parseInt(item.itemId);
            const uniqKey = toOrderItemUniqKey(picklistId, id, itemId);
            const defaultItem = {
              itemId,
              picklistCategoryId: id,
              inventorySnapshot,
              quantity: 0
            };
            const orderItem = getOr(defaultItem, uniqKey, orderItemsByKey);
            return (
              <PicklistItem
                data={item}
                key={`item-${uniqKey}`}
                orderItem={orderItem}
                onChangeOrderItem={handleChangeOrderItem}
                categoryLimit={quantityLimit}
                limitReached={picklistLimitReached || limitReached}
              />
            );
          }, items)}
        </Grid>
      </Grid>
      <Divider style={{ marginBottom: 16 }} />
    </>
  );
};

PicklistCategory.propTypes = {
  data: PicklistCategoryType.isRequired,
  items: PropTypes.arrayOf(PicklistItemType),
  orderItems: PropTypes.arrayOf(PropTypes.shape({})),
  picklistId: PropTypes.number,
  picklistLimit: PropTypes.number,
  picklistLimitReached: PropTypes.bool.isRequired,
  onChangeOrderItems: PropTypes.func.isRequired
};

PicklistCategory.defaultProps = {
  items: [],
  orderItems: [],
  picklistId: null,
  picklistLimit: 0
};

export default PicklistCategory;
