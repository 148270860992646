import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import parseQueryString from '../../helpers/parseQueryString';

export default () => {
  const { search } = useLocation();
  const [query, setQuery] = useState({});
  useEffect(() => {
    setQuery(parseQueryString(search));
    return function cleanup() {
      setQuery({});
    };
  }, [search]);
  return query;
};
