import React from 'react';
import PropTypes from 'prop-types';
import signOnLogo from '../../assets/images/soxboxLogoGrey.png';

const GreyLogo = ({ width, ...props }) => {
  return <img src={signOnLogo} alt="Sox Box" width={width} {...props} />;
};

GreyLogo.propTypes = {
  width: PropTypes.number
};

GreyLogo.defaultProps = {
  width: 250
};

export default GreyLogo;
