import React, { useEffect, useMemo } from 'react';
import { useDebounce } from 'use-debounce';
import { get, isNull } from 'lodash/fp';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Box } from '@material-ui/core';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { parseInt } from '../../helpers/utils';
import getItemAvailabilityUniqKey from '../../helpers/inventory/getItemAvailabilityUniqKey';
import {
  ItemBox,
  DeductButton,
  AddButton,
  QuantityInput,
  ItemLimit
} from './elements';
import guestQuantityLimit from '../../helpers/guestQuantityLimit';
import PicklistItemType from '../../interfaces/PicklistItemType';
import InventorySnapshotType from '../../interfaces/InventorySnapshotType';
import S3Image from '../S3Image';
import HealthLevelIndicator from '../HealthLevelIndicator';

const getItemLimit = (guestInfo, limitRules, categoryLimit) => {
  const itemLimit = limitRules ? guestQuantityLimit(guestInfo, limitRules) : 0;
  if (itemLimit === 0 || (categoryLimit > 0 && itemLimit > categoryLimit))
    return categoryLimit;

  return itemLimit;
};

const PicklistItem = ({
  data: { name, photo, healthLevel, limitRules = null, inventorySnapshot },
  orderItem,
  categoryLimit,
  onChangeOrderItem,
  limitReached,
  ...props
}) => {
  const { pantryPath } = useParams();
  const { quantity } = orderItem;
  const itemUniqKey = getItemAvailabilityUniqKey(inventorySnapshot);
  const guestInfo = useStoreState(get('userDetails.data.otherInfo'));
  const itemSumQuantity =
    useStoreState(
      get(`pages.reservationOrder.itemsSumQuantityByUniqKey.${itemUniqKey}`)
    ) || 0;
  const initialSumQuantity =
    useStoreState(
      get(
        `pages.reservationOrder.itemsInitialSumQuantityByUniqKey.${itemUniqKey}`
      )
    ) || 0;

  const isLoadingItemAvailability = useStoreState(
    get('inventory.itemsAvailabilityByUniqKey.loading')
  );
  const itemAvailability = useStoreState(
    get(`inventory.itemsAvailabilityByUniqKey.data.${itemUniqKey}`)
  );
  const fetchItemAvailability = useStoreActions(
    get('inventory.itemsAvailabilityByUniqKey.fetch')
  );
  const hasLimit = !isNull(limitRules);
  const itemLimit = getItemLimit(guestInfo, limitRules, categoryLimit);

  const isLinkedItem = !!inventorySnapshot;
  const availableQuantity =
    get('quantity', itemAvailability) - get('onholdQuantity', itemAvailability);

  const exceededInventoryLimit = useMemo(() => {
    if (!isLinkedItem || !itemSumQuantity) return false;
    return itemSumQuantity - initialSumQuantity > availableQuantity;
  }, [itemSumQuantity, availableQuantity]);

  const disabledIncrementBtn =
    limitReached ||
    (hasLimit && quantity >= itemLimit) ||
    exceededInventoryLimit;

  const handleInputChange = ({ target }) => {
    const newQuantity = parseInt(target.value) || 0;
    if (newQuantity && newQuantity < 0) return;

    const hasReachedItemLimit = hasLimit && newQuantity > itemLimit;
    if ((limitReached && newQuantity > quantity) || hasReachedItemLimit) {
      return;
    }
    onChangeOrderItem({ ...orderItem, quantity: newQuantity });
  };

  const handleIncrement = () => {
    const newQuantity = quantity + 1;
    const hasReachedItemLimit = hasLimit && newQuantity > itemLimit;
    if ((limitReached && newQuantity > quantity) || hasReachedItemLimit) {
      return;
    }
    onChangeOrderItem({ ...orderItem, quantity: newQuantity });
  };

  const handleDecrement = () => {
    if (quantity === 0) return;
    onChangeOrderItem({ ...orderItem, quantity: quantity - 1 });
  };

  const [debouncedQuantity] = useDebounce(quantity, 500);
  useEffect(() => {
    if (isLoadingItemAvailability) return;
    if (isLinkedItem && debouncedQuantity > 0 && !itemAvailability) {
      const itemId = get('item.id', inventorySnapshot);
      const locationId = get('location.id', inventorySnapshot);
      const zoneId = get('zone.id', inventorySnapshot);
      fetchItemAvailability({ pantryPath, itemId, locationId, zoneId });
    }
  }, [debouncedQuantity]);

  const selectedClass = quantity > 0 ? 'selected' : '';
  const errorClass = quantity > 0 && exceededInventoryLimit ? 'error' : '';

  return (
    <ItemBox
      {...props}
      textAlign="center"
      mr={{ xs: 0, sm: 2 }}
      mx={{ xs: 2, sm: 1 }}
      className={`${selectedClass} ${errorClass}`}
    >
      <Box>
        {healthLevel > 0 && (
          <HealthLevelIndicator className="health-level" value={healthLevel} />
        )}
        {hasLimit && <ItemLimit>Max: {itemLimit}</ItemLimit>}
        {parse(name)}
        {photo && (
          <S3Image
            height="auto"
            image={photo}
            style={{
              maxWidth: 100,
              maxHeight: 70,
              display: 'block',
              margin: '5px auto'
            }}
          />
        )}
      </Box>
      <Box textAlign="center">
        <DeductButton
          size="small"
          variant="contained"
          onClick={handleDecrement}
          disabled={quantity === 0}
        >
          &ndash;
        </DeductButton>
        <QuantityInput
          size="small"
          value={quantity}
          onChange={handleInputChange}
        />
        <AddButton
          size="small"
          variant="contained"
          onClick={handleIncrement}
          disabled={disabledIncrementBtn}
        >
          +
        </AddButton>
      </Box>
    </ItemBox>
  );
};

PicklistItem.propTypes = {
  data: PicklistItemType.isRequired,
  orderItem: PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    picklistCategoryId: PropTypes.string.isRequired,
    inventorySnapshot: InventorySnapshotType
  }).isRequired,
  onChangeOrderItem: PropTypes.func.isRequired,
  categoryLimit: PropTypes.number.isRequired,
  limitReached: PropTypes.bool.isRequired
};

export default PicklistItem;
