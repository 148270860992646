/* eslint-disable no-param-reassign */
import { action } from 'easy-peasy';

export default {
  data: null,
  remove: action(state => {
    state.data = null;
  }),
  clear: action(state => {
    state.data = null;
  }),
  showInfo: action((state, { message, ...props }) => {
    state.data = { ...props, type: 'info', message };
  }),
  showSuccess: action((state, { message, ...props }) => {
    state.data = { ...props, type: 'success', message };
  }),
  showWarning: action((state, { message, ...props }) => {
    state.data = { ...props, type: 'warning', message };
  }),
  showError: action((state, { message, ...props }) => {
    state.data = { ...props, type: 'error', message };
  })
};
