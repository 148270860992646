/* eslint-disable no-param-reassign */
import { action, thunk } from 'easy-peasy';
import {
  createReservationOrder,
  updateReservationOrder
} from '../../services/fbm-api';

export default {
  data: null,
  error: null,
  loading: false,
  set: action((state, data) => {
    state.data = data;
  }),
  clear: action(state => {
    state.data = null;
  }),
  create: thunk(async (actions, payload) => {
    try {
      actions.setLoading(true);
      const orders = await createReservationOrder(payload);
      actions.set(orders);
    } catch (error) {
      actions.setError(error);
    } finally {
      actions.setLoading(false);
    }
  }),
  update: thunk(async (actions, payload) => {
    try {
      actions.setLoading(true);
      const orders = await updateReservationOrder(payload);
      actions.set(orders);
    } catch (error) {
      actions.setError(error);
    } finally {
      actions.setLoading(false);
    }
  }),
  setLoading: action((state, loading) => {
    state.loading = loading;
  }),
  setError: action((state, error) => {
    state.error = error;
  }),
  clearError: action(state => {
    state.error = null;
  })
};
