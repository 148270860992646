import React, { useEffect, useState } from 'react';
import { get } from 'lodash/fp';
import styled from 'styled-components';
import { useStoreActions } from 'easy-peasy';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import EventIcon from '@material-ui/icons/EventOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useCurrentUserCookie from '../../../hooks/useCurrentUserCookie';
import {
  login as loginPath,
  guestDashboard as guestDashboardPath,
  guestEditProfile as guestEditProfilePath
} from '../../../paths';
import history from '../../../history';
import ProfileIconWithVerifiedBadge from '../../ProfileIconWithVerifiedBadge';
import ReservationsButtonNav from '../../ReservationsButtonNav';
import NavLink from '../NavLink';

const StyledMenuItem = styled(MenuItem)`
  padding-top: 8px;
  padding-bottom: 8px;
`;

export default () => {
  const currentUser = useCurrentUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const { get: getUserCookie } = useCurrentUserCookie();
  const showVerifiedBadge = getUserCookie('hideVerifyEmailAlert') !== '1';
  const isVerifiedLoginEmail = !!get('verifiedAt', currentUser);
  const logOut = useStoreActions(get('user.logOut'));
  const { setShowDialog, setShowLoginForm } = useStoreActions(
    get('components.loginSignupDialog')
  );
  const menuId = 'MainMenu';
  const isMenuOpen = Boolean(anchorEl);

  const handleShowLogin = () => {
    setShowDialog(true);
    setShowLoginForm(true);
  };

  const handleLogout = async () => {
    setIsLoggingOut(true);
    await logOut();
    history.push(loginPath);
  };

  const handleGotoDashboard = () => {
    history.push(guestDashboardPath);
  };

  const handleGoToProfile = () => {
    history.push(guestEditProfilePath);
  };

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    return () => {
      setIsLoggingOut(false);
    };
  }, []);

  if (!currentUser)
    return (
      <>
        <Button
          color="inherit"
          onClick={handleShowLogin}
          startIcon={<LockOutlinedIcon />}
        >
          Login
        </Button>
      </>
    );

  return (
    <>
      {/* Desktop Menu */}
      <Hidden smDown>
        <Box mt={-1}>
          <NavLink onClick={handleGotoDashboard}>
            <DashboardIcon fontSize="small" />
            Dashboard
          </NavLink>
          <NavLink onClick={handleGoToProfile}>
            <ProfileIconWithVerifiedBadge
              verified={isVerifiedLoginEmail}
              showBadge={showVerifiedBadge}
            />
            Profile
          </NavLink>
          <NavLink onClick={handleLogout}>
            <ExitToAppIcon fontSize="small" />
            Log Out
          </NavLink>
          <Box mt={2} textAlign="right">
            <ReservationsButtonNav
              variant="outlined"
              startIcon={<EventIcon />}
              onClick={handleGotoDashboard}
            >
              View My Reservations
            </ReservationsButtonNav>
          </Box>
        </Box>
      </Hidden>
      {/* End of Desktop Menu */}

      {/* Mobile Menu */}
      <Hidden mdUp>
        <IconButton
          aria-label="Reservations"
          onClick={handleGotoDashboard}
          color="inherit"
        >
          <EventIcon />
        </IconButton>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="Menu"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id={menuId}
          keepMounted
          open={isMenuOpen}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={handleMenuClose}
        >
          <StyledMenuItem onClick={handleGotoDashboard}>
            <DashboardIcon /> &nbsp; Dashboard
          </StyledMenuItem>
          <StyledMenuItem onClick={handleGoToProfile}>
            <ProfileIconWithVerifiedBadge
              verified={isVerifiedLoginEmail}
              showBadge={showVerifiedBadge}
            />
            &nbsp; Profile
          </StyledMenuItem>
          <Divider light />
          <StyledMenuItem onClick={handleLogout}>
            <ExitToAppIcon /> &nbsp;
            {isLoggingOut ? 'Logging out...' : 'Log-out'}
          </StyledMenuItem>
        </Menu>
      </Hidden>
      {/* End of Mobile Menu */}
    </>
  );
};
