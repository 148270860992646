import React from 'react';
import PropTypes from 'prop-types';
import { set, getOr, map } from 'lodash/fp';
import { Box } from '@material-ui/core';
import Picklist from '../../../components/Picklist';

const defaultOrder = picklistId => ({
  picklistId,
  notes: '',
  items: []
});

function ProgramsPicklists({ picklists, orders, onChangeOrders }) {
  const handleChangeOrder = order => {
    const updatedOrders = set(order.picklistId, order, orders);
    onChangeOrders(updatedOrders);
  };

  return (
    <Box mt={1} textAlign={{ xs: 'center', sm: 'left' }}>
      {map(
        each => (
          <Picklist
            data={each}
            key={`picklist-${each.id}`}
            order={getOr(defaultOrder(each.id), each.id, orders)}
            onChange={handleChangeOrder}
          />
        ),
        picklists
      )}
    </Box>
  );
}

ProgramsPicklists.propTypes = {
  orders: PropTypes.shape({}), // ordersByPicklistId
  picklists: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChangeOrders: PropTypes.func.isRequired
};

ProgramsPicklists.defaultProps = {
  orders: {}
};

export default ProgramsPicklists;
