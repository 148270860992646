import { set, map, reduce } from 'lodash/fp';

export default reduce(
  (ordersByPicklist, { id = null, picklistId, notes, items }) =>
    set(
      picklistId,
      {
        id,
        picklistId,
        notes,
        items: map(
          ({
            id: orderItemId = null,
            itemId,
            picklistCategoryId,
            quantity,
            inventorySnapshot = null
          }) => ({
            id: orderItemId,
            itemId,
            picklistCategoryId,
            quantity,
            inventorySnapshot
          }),
          items
        )
      },
      ordersByPicklist
    ),
  {}
);
