import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import './assets/css/fonts.css';
import App from './App';
import sentryConfig from './config/sentry';
import * as serviceWorker from './serviceWorker';

Sentry.init(sentryConfig);

ReactDOM.render(
  <App />,
  document.querySelector('#root') // eslint-disable-line no-undef
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
