/* eslint-disable no-param-reassign */
import { action } from 'easy-peasy';

export default {
  event: null,
  timeSlot: '',
  set: action((state, { event, timeSlot }) => {
    state.event = event;
    state.timeSlot = timeSlot;
  })
};
