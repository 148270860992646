import React, { useEffect, useState } from 'react';
import { get, values, isEmpty } from 'lodash/fp';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Link from '@material-ui/core/Link';
import Layout from '../../components/Layout';
import {
  login as loginPath,
  reservationSummary as reservationSummaryPath
} from '../../paths';
import { getCurrentUser } from '../../provider/currentUser';
import useCurrentUserCookie from '../../hooks/useCurrentUserCookie';
import PageHeading from '../../components/PageHeading';
import PageSection from '../../components/PageSection';
import PageLoading from '../../components/PageLoading';
import VerifyEmailButton from '../../components/VerifyEmailButton';
import Reservations from './Reservations';
import VisitedPantries from './VisitedPantries';
import pagePathToUrl from '../../helpers/pagePathToUrl';

export default function HomePage() {
  const userSession = getCurrentUser();
  const { get: getUserCookie, set: setUserCookie } = useCurrentUserCookie();
  const [showVerifyEmailAlert, setShowVerifyEmailAlert] = useState(
    getUserCookie('hideVerifyEmailAlert') !== '1'
  );

  const userId = get('id', userSession);
  const isVerifiedLoginEmail = !!get('verifiedAt', userSession);
  const fetchReservations = useStoreActions(get('userReservations.fetch'));
  const fetchVisitedPantries = useStoreActions(
    get('userVisitedPantries.fetch')
  );
  const {
    data: reservations,
    loading: loadingResevations,
    incomplete: incompleteReservation
  } = useStoreState(get('userReservations'));
  const {
    data: visitedPantries,
    loading: loadingVisitedPantries
  } = useStoreState(get('userVisitedPantries'));

  const handleCloseVerifyEmail = () => {
    setShowVerifyEmailAlert(false);
    setUserCookie('hideVerifyEmailAlert', 1);
  };

  useEffect(() => {
    if (userId) {
      fetchReservations(userId);
      fetchVisitedPantries(userId);
    }
  }, [userId, fetchReservations, fetchVisitedPantries]);

  if (!userSession) {
    return <Redirect to={loginPath} />;
  }

  return (
    <Layout>
      {loadingResevations || loadingVisitedPantries ? (
        <PageLoading />
      ) : (
        <>
          {!isVerifiedLoginEmail && showVerifyEmailAlert && (
            <Box mb={2}>
              <Alert severity="warning" onClose={handleCloseVerifyEmail}>
                {`Your Login Email is not yet verified. `}
                <VerifyEmailButton />
              </Alert>
            </Box>
          )}
          {incompleteReservation && (
            <Box mb={2}>
              <Alert severity="warning">
                {`Your Reservation is still incomplete. `}
                <Link
                  to={pagePathToUrl(reservationSummaryPath, {
                    id: get('id', incompleteReservation),
                    pantryPath: get('pantrySite.path', incompleteReservation)
                  })}
                  variant="body2"
                  component={RouterLink}
                >
                  Please click here to complete your reservation.
                </Link>
              </Alert>
            </Box>
          )}
          <Box mb={4}>
            <PageHeading>My Reservations</PageHeading>
            <PageSection>
              <Reservations data={values(reservations)} />
            </PageSection>
          </Box>
          {!isEmpty(visitedPantries) && (
            <Box>
              <PageHeading>Make A New Reservation</PageHeading>
              <PageSection>
                <VisitedPantries data={values(visitedPantries)} />
              </PageSection>
            </Box>
          )}
        </>
      )}
    </Layout>
  );
}
