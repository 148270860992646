import PropTypes from 'prop-types';
import LimitRulesType from './LimitRulesType';

const NumberOrStringTepe = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string
]);

export default PropTypes.shape({
  name: PropTypes.string,
  photo: PropTypes.string,
  itemId: NumberOrStringTepe,
  category: PropTypes.string,
  categoryId: PropTypes.string,
  healthLevel: NumberOrStringTepe,
  position: PropTypes.number,
  limitRules: LimitRulesType
});
