import { stringify } from 'qs';
import { get, set, isObject, inRange } from 'lodash/fp';
import { clearCurrentUser } from '../../../provider/currentUser';
import {
  getAccessToken,
  clearAccessToken
} from '../../../provider/accessToken';

export const prepareRequestOptions = ({ body, headers, ...options }) => ({
  ...options,
  headers: set('Content-Type', 'application/json', headers),
  ...(isObject(body) ? { body: JSON.stringify(body) } : {})
});

export const composeRequestUrl = (uri, qs = {}) => {
  const url = `/rest-api/public/${uri}`;
  const queryString = stringify(qs || {}, { encodeValuesOnly: true });
  if (queryString) {
    return `${url}?${queryString}`;
  }
  return url;
};

const prepareRequestHeaders = headers => {
  const accessToken = getAccessToken();
  if (accessToken) {
    return set('Authorization', `Bearer ${accessToken}`, headers);
  }

  return headers;
};

export default async function apiRequest(uri, { qs, ...options } = {}) {
  const url = composeRequestUrl(uri, qs);
  const headers = prepareRequestHeaders(options.headers);
  const fetchOptions = prepareRequestOptions({ ...options, headers });

  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(url, fetchOptions);
    const body = await response.json();

    if (!inRange(100, 299, response.status)) {
      throw body;
    }

    return body;
  } catch (error) {
    const isUnauthorized = get('code', error) === 401;
    const isUnknownError = get('name', error) === 'TypeError';
    if (isUnauthorized || isUnknownError) {
      clearCurrentUser();
      clearAccessToken();
    }

    if (isUnknownError)
      throw new Error('Unknown error encountered. Please try again.');

    throw error;
  }
}
