import React, { useState } from 'react';
import * as yup from 'yup';
import { has, getOr, get } from 'lodash/fp';
import { useForm } from 'react-hook-form';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStoreActions } from 'easy-peasy';
import useStyles from '../../provider/styles/form';
import Logo from '../../components/Logo';
import Copyright from '../../components/Copyright';
import { passwordValidation } from '../../components/SignupForm';
import {
  verifyResetPassword,
  requestResetPassword
} from '../../services/userAuth';
import parseQueryString from '../../helpers/parseQueryString';
import { login as loginPath } from '../../paths';
import history from '../../history';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Must be a valid email')
    .required('Required field'),
  code: yup.string().required('Required field'),
  newPassword: passwordValidation,
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword')], 'Passwords must match')
});

export default function ForgotPassword() {
  const classes = useStyles();
  const location = useLocation();
  const params = parseQueryString(location.search, { decoder: decodeURI });
  const defaultValues = {
    email: getOr('', 'email', params),
    code: getOr('', 'code', params)
  };
  const { register, handleSubmit, errors } = useForm({
    validationSchema,
    defaultValues
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestingCode, setIsRequestingCode] = useState(false);
  const { showError, showSuccess } = useStoreActions(get('notifications'));

  const onSubmit = async ({ email, code, newPassword }) => {
    try {
      setIsLoading(true);
      await verifyResetPassword(email, code, newPassword);
      showSuccess({
        message: 'Password successfully reset. You may now login.'
      });
      history.push(loginPath);
    } catch (error) {
      showError({
        message: getOr('Invalid email, code or password.', 'message', error)
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRequestCode = async () => {
    try {
      setIsRequestingCode(true);
      await requestResetPassword(params.email);
      showSuccess({
        message: 'Verification code has been sent. Please check your email.'
      });
    } catch (error) {
      showError({ message: get('message', error) });
    } finally {
      setIsRequestingCode(false);
    }
  };

  const isError = field => has(field, errors);
  const getFieldError = field => {
    return getOr('', `${field}.message`, errors);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Logo />
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h5" align="center">
          Reset Password Verification
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                disabled
                variant="outlined"
                required
                fullWidth
                name="email"
                size="small"
                label="Email Address"
                inputRef={register}
                error={isError('email')}
                helperText={getFieldError('email')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                autoFocus={!has('code', params)}
                name="code"
                size="small"
                variant="outlined"
                label="Verification Code"
                disabled={isLoading}
                autoComplete="off"
                inputRef={register}
                error={isError('code')}
                helperText={getFieldError('code')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                autoFocus={has('code', params)}
                type="password"
                name="newPassword"
                size="small"
                label="New Password"
                autoComplete="email"
                disabled={isLoading}
                inputRef={register}
                error={isError('newPassword')}
                helperText={getFieldError('newPassword')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                size="small"
                inputRef={register}
                error={isError('confirmPassword')}
                helperText={getFieldError('confirmPassword')}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isLoading}
          >
            {(!isLoading && 'Submit') || (
              <CircularProgress size={24} color="inherit" />
            )}
          </Button>
          <Button
            type="button"
            fullWidth
            variant="contained"
            disabled={isRequestingCode}
            onClick={handleRequestCode}
          >
            {(!isRequestingCode && 'Request another code') || (
              <CircularProgress size={24} color="inherit" />
            )}
          </Button>
          <Box mt={1} textAlign="right">
            <Link to="/forgot-password" variant="body2" component={RouterLink}>
              Back
            </Link>
            &nbsp;|&nbsp;
            <Link to="/login" variant="body2" component={RouterLink}>
              Login
            </Link>
          </Box>
        </form>
        <Box mt={3}>
          <Copyright />
        </Box>
      </div>
    </Container>
  );
}
