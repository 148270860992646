import fp from 'lodash/fp';
import { setCookie, getCookie, removeCookie } from 'react-use-cookie';
import useCurrentUser from '../useCurrentUser';

const cookieSettings = {
  days: 30
};

export default () => {
  const user = useCurrentUser();
  const cookiePrefixName = fp.get('id', user);

  const set = (key, value, settings = cookieSettings) => {
    setCookie(`${cookiePrefixName}.${key}`, value, settings);
  };

  const get = key => {
    return getCookie(`${cookiePrefixName}.${key}`);
  };

  const remove = key => {
    return removeCookie(`${cookiePrefixName}.${key}`);
  };

  return { get, set, remove };
};
