import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  // necessary for content to be below app bar
  content: {
    flexGrow: 1,
    marginTop: 40,
    [theme.breakpoints.up('md')]: {
      marginTop: 80
    }
  }
}));
