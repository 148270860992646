/* eslint-disable no-param-reassign */
import { keyBy } from 'lodash/fp';
import { action, thunk } from 'easy-peasy';
import {
  fetchUserVisitedPantries,
  deleteUserVisitedPantry
} from '../../services/fbm-api';

export default {
  data: null,
  loading: false,
  error: null,
  deleted: false,
  deleteError: null,
  fetch: thunk(async (actions, payload) => {
    try {
      actions.setLoading(true);
      const { items } = await fetchUserVisitedPantries(payload);
      actions.set(keyBy('id', items));
    } catch (error) {
      actions.setError(error);
    } finally {
      actions.setLoading(false);
    }
  }),
  delete: thunk(async (actions, payload) => {
    try {
      actions.setDeleted(false);
      actions.setDeleteError(null);
      await deleteUserVisitedPantry(payload);
      const { pantrySiteId } = payload;
      actions.setDeleted(true);
      actions.onDeleteSuccess(pantrySiteId);
    } catch (error) {
      actions.setDeleteError(error);
    }
  }),
  onDeleteSuccess: action((state, pantrySiteId) => {
    delete state.data[pantrySiteId];
  }),
  set: action((state, payload) => {
    state.data = payload;
  }),
  setLoading: action((state, loading) => {
    state.loading = loading;
  }),
  setDeleted: action((state, deleted) => {
    state.deleted = deleted;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setDeleteError: action((state, payload) => {
    state.deleteError = payload;
  })
};
