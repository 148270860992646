import React from 'react';
import DoneIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import CancelIcon from '@material-ui/icons/Cancel';
import PendingIcon from '@material-ui/icons/Timelapse';
import ApprovedIcon from '@material-ui/icons/ThumbUpAlt';
import ConfirmedIcon from '@material-ui/icons/VerifiedUser';
import {
  ON_HOLD,
  PENDING,
  ACTIVE,
  CONFIRMED,
  COMPLETED,
  CANCELED,
  REJECTED
} from './ReservationStatus';

const style = { marginRight: 1 };

export default {
  [ON_HOLD]: <WarningIcon fontSize="small" style={style} />,
  [PENDING]: <PendingIcon fontSize="small" style={style} />,
  [ACTIVE]: <ApprovedIcon fontSize="small" style={style} />,
  [CONFIRMED]: <ConfirmedIcon fontSize="small" style={style} />,
  [COMPLETED]: <DoneIcon style={style} />,
  [CANCELED]: <CancelIcon style={style} />,
  [REJECTED]: <CancelIcon style={style} />
};
