import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ContactsRounded, Email, Phone } from '@material-ui/icons';
import { useStoreState } from 'easy-peasy';
import { get } from 'lodash/fp';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Link from '@material-ui/core/Link';

export default function ContactDetailsDialog({ open, onClose }) {
  const pantry = useStoreState(get('pantry.data'));
  const contactName = get('contactName', pantry);
  const contactEmail = get('contactEmail', pantry);
  const contactNumber = get('contactNumber', pantry);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">Contact Details</DialogTitle>
      <DialogContent>
        <List component="nav" aria-label="main mailbox folders">
          <ListItem>
            <ListItemIcon>
              <ContactsRounded />
            </ListItemIcon>
            <ListItemText primary={contactName} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Phone />
            </ListItemIcon>
            <Link color="inherit" href={`tel:${contactNumber}`}>
              <ListItemText primary={contactNumber} />
            </Link>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Email />
            </ListItemIcon>
            <Link color="inherit" href={`mailto:${contactEmail}`}>
              <ListItemText primary={contactEmail} />
            </Link>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ContactDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
