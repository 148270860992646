import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { has, set, map, reject, reduce, isEqual, parseInt } from 'lodash/fp';
import { Grid } from '@material-ui/core';
import ProgramFormControl from '../../../../components/form/ProgramFormControl';

function ProgramsSelection({ programs, onSelect, disabled, selected }) {
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [checkedPrograms, setCheckedPrograms] = useState({});

  const handleSelectProgram = ({ target }) => {
    const { value, checked } = target;
    const programId = parseInt(10, value);
    const updatedValue = checked
      ? [...selectedPrograms, programId]
      : reject(isEqual(programId), selectedPrograms);

    onSelect(updatedValue);
    setSelectedPrograms(updatedValue);
  };

  useEffect(() => {
    setSelectedPrograms(selected);
    return () => {
      setSelectedPrograms([]);
    };
  }, [selected]);

  useEffect(() => {
    setCheckedPrograms(
      reduce((all, id) => set(id, true, all), {}, selectedPrograms)
    );
    return () => {
      setCheckedPrograms({});
    };
  }, [selectedPrograms]);

  return (
    <Grid container spacing={2} style={{ position: 'relative', left: '10px' }}>
      {map(
        program => (
          <Grid item xs={12} sm={6} md={3} key={`program-${program.id}`}>
            <ProgramFormControl
              program={program}
              disabled={disabled}
              checked={has(program.id, checkedPrograms)}
              onChange={handleSelectProgram}
            />
          </Grid>
        ),
        programs
      )}
    </Grid>
  );
}

ProgramsSelection.propTypes = {
  programs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selected: PropTypes.arrayOf(PropTypes.number),
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

ProgramsSelection.defaultProps = {
  selected: []
};

export default ProgramsSelection;
