import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get, getOr, map } from 'lodash/fp';
import { Grid } from '@material-ui/core';
import { useStoreActions, useStoreState } from 'easy-peasy';
import useCurrentUser from '../../../hooks/useCurrentUser';
import PantryCard from '../../../components/PantryCard';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import PantryType from '../../../interfaces/PantryType';

const VisitedPantries = ({ data }) => {
  const user = useCurrentUser();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [visitedPantryToDelete, setVisitedPantryToDelete] = useState(false);
  const { deleteError, deleted } = useStoreState(get('userVisitedPantries'));
  const { showSuccess, showError } = useStoreActions(get('notifications'));
  const {
    setDeleted,
    setDeleteError,
    delete: deleteVisitedPantry
  } = useStoreActions(get('userVisitedPantries'));
  const handleOnDelete = pantry => {
    setShowDeleteConfirmation(true);
    setVisitedPantryToDelete(pantry);
  };

  const handleConfirmDelete = () => {
    const pantrySiteId = visitedPantryToDelete.id;
    deleteVisitedPantry({ userId: user.id, pantrySiteId });
    setShowDeleteConfirmation(false);
  };

  const handleHideDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  useEffect(() => {
    if (deleted && visitedPantryToDelete) {
      showSuccess({ message: 'Successfully removed visited pantry.' });
      setDeleted(false);
      setVisitedPantryToDelete(null);
    }
  }, [deleted, visitedPantryToDelete]);

  useEffect(() => {
    if (deleteError) {
      const message = getOr(
        'Server Error: Unable to delete. Please try again later.',
        'message',
        deleteError
      );
      showError({ message });
      setDeleteError(null);
    }
  }, [deleteError]);

  return (
    <Grid container spacing={2}>
      {map(
        pantry => (
          <Grid item xs={12} sm={6} md={4} key={`pantry-${get('id', pantry)}`}>
            <PantryCard data={pantry} onDelete={handleOnDelete} />
          </Grid>
        ),
        data
      )}
      <ConfirmationDialog
        message={`Are you sure you want to remove "${get(
          'name',
          visitedPantryToDelete
        )}" from this list?`}
        open={showDeleteConfirmation}
        onConfirm={handleConfirmDelete}
        onClose={handleHideDeleteConfirmation}
      />
    </Grid>
  );
};

VisitedPantries.propTypes = {
  data: PropTypes.arrayOf(PantryType).isRequired
};

export default VisitedPantries;
