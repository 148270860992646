export const dashboard = '/dashboard';

export const pantryBasePath = '/pantry/:pantryPath';
export const reservationSummary = `${pantryBasePath}/reservations/:id/summary`;
export const reservationOrder = `${pantryBasePath}/reservations/:id/order`;

export const home = '/';
export const login = '/login';
export const logout = '/logout';
export const checkAuth = '/check-auth';

export const emailVerified = '/email-verified';
export const forgotPassword = '/forgot-password';
export const emailVerification = `/email-verification`;
export const resetPasswordVerification = '/reset-password-verification';

export const guestDashboard = '/guest/dashboard';
export const guestEditProfile = '/guest/edit-profile';
