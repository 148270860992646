/* eslint-disable no-param-reassign */
import moment from 'moment';
import { action, thunk, computed } from 'easy-peasy';
import {
  get,
  set,
  omit,
  keyBy,
  flow,
  find,
  values,
  filter,
  reduce
} from 'lodash/fp';
import {
  fetchUserReservations,
  updateReservationStatus,
  deleteReservation
} from '../../services/fbm-api';
import { ON_HOLD, CANCELED } from '../../constants/ReservationStatus';

export default {
  data: null,
  loading: false,
  saving: false,
  error: null,
  incomplete: computed(
    flow(
      get('data'),
      values,
      find(
        ({ status, requestTime }) =>
          status === ON_HOLD && moment(requestTime).isAfter(moment())
      )
    )
  ),
  reservationsByOutreachIdForPantry: computed(({ data }) => pantrySiteId =>
    flow(
      filter(
        each => each.pantrySiteId === pantrySiteId && each.status !== CANCELED
      ),
      reduce(
        (byOutreachIdData, { id, status, meta: { outreachId } }) =>
          set(outreachId, { id, status }, byOutreachIdData),
        {}
      )
    )(data)
  ),
  fetch: thunk(async (actions, payload) => {
    try {
      actions.setLoading(true);
      const { items } = await fetchUserReservations(payload);
      actions.set(keyBy('id', items));
    } catch (error) {
      actions.setError(error);
    } finally {
      actions.setLoading(false);
    }
  }),
  updateStatus: thunk(async (actions, payload) => {
    try {
      actions.setSaving(true);
      const reservation = await updateReservationStatus(payload);
      actions.setItem(reservation);
    } catch (error) {
      actions.setError(error);
    } finally {
      actions.setSaving(false);
    }
  }),
  delete: thunk(async (actions, payload) => {
    try {
      actions.setSaving(true);
      await deleteReservation(payload);
      actions.removeItem(payload.id);
    } catch (error) {
      actions.setError(error);
    } finally {
      actions.setSaving(false);
    }
  }),
  set: action((state, payload) => {
    state.data = payload;
  }),
  setItem: action((state, payload) => {
    const item = get(payload.id, state.data);
    state.data = set(payload.id, { ...item, ...payload }, state.data);
  }),
  removeItem: action((state, id) => {
    state.data = omit(id, state.data);
  }),
  setLoading: action((state, loading) => {
    state.loading = loading;
  }),
  setSaving: action((state, saving) => {
    state.saving = saving;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  })
};
