import PropTypes from 'prop-types';
import DateType from './DateType';

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  otherInfo: PropTypes.shape({
    homeless: PropTypes.string.isRequired,
    householdTotal: PropTypes.number,
    streetAddress: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
    cellPhone: PropTypes.string,
    workPhone: PropTypes.string,
    homePhone: PropTypes.string,
    birthDate: DateType,
    gender: PropTypes.string
  }),
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
  deletedAt: PropTypes.string
});
