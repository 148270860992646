import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export default styled(props => <Typography variant="h4" {...props} />)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
  :hover {
    cursor: pointer;
  }
`;
