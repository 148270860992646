import { createTheme } from '@material-ui/core/styles';

const black = '#000000';
const white = '#ffffff';
const gray = 'gray';
const lightGray = '#efefef';
const darkGray = '#555';

const theme = createTheme({
  palette: {
    common: { black, white, gray, lightGray, darkGray },
    primary: {
      main: '#1074ba',
      light: '#86cce7',
      pastel: '#559cce',
      dark: '#1a6683'
    },
    secondary: {
      dark: '#ad6930',
      main: '#f89645',
      light: '#f9ab6a',
      mainRgb: '248, 150, 69'
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff'
    },
    success: {
      main: '#4caf50'
    }
  }
});

export default theme;
