import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import htmlParser from 'html-react-parser';

const StyledDescription = styled.div`
  .u-row-container > div {
    margin: 0 auto;
  }
  ${({ styles }) => styles}
`;

const PantryDetails = ({ pantry }) => {
  const [descriptionBody, setDescriptionBody] = useState('');
  const [descriptionStyles, setDescriptionStyles] = useState('');

  useEffect(() => {
    if (!pantry) return;
    const { body, css } = JSON.parse(pantry.description);
    const bodyContent = body
      .replace('<body', '<div')
      .replace('</body>', '</div>');
    setDescriptionBody(bodyContent);
    setDescriptionStyles(css);
  }, [pantry]);

  return (
    <StyledDescription styles={descriptionStyles}>
      {htmlParser(descriptionBody, { trim: true })}
    </StyledDescription>
  );
};

PantryDetails.propTypes = {
  pantry: PropTypes.shape({
    description: PropTypes.string
  })
};

PantryDetails.defaultProps = {
  pantry: null
};

export default memo(PantryDetails);
