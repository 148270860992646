import PropTypes from 'prop-types';
import DateType from './DateType';
import NumberOrStringType from './NumberOrStringType';

export default PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  streetAddress: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zipCode: PropTypes.string.isRequired,
  country: PropTypes.string,
  latitude: NumberOrStringType,
  longitude: NumberOrStringType,
  createdAt: DateType,
  updatedAt: DateType,
  deletedAt: DateType
});
