import { Integrations } from '@sentry/tracing';

export default {
  // eslint-disable-next-line no-undef
  dsn: localStorage.getItem('SENTRY_DNS'),
  // eslint-disable-next-line no-undef
  environment: localStorage.getItem('ENVIRONMENT'),
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.2
};
