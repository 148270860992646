import PropTypes from 'prop-types';
import TimeSlotType from './EventTimeSlotType';

export const EventTrackingMethodType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  isMatch: PropTypes.bool
});

export default PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  schedule: PropTypes.arrayOf(TimeSlotType).isRequired,
  scheduleMinutesInterval: PropTypes.number.isRequired,
  trackingMethods: PropTypes.arrayOf(EventTrackingMethodType).isRequired
});
