import React, { useEffect } from 'react';
import { get } from 'lodash/fp';
import { useStoreActions, useStoreState } from 'easy-peasy';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Dialog from '../../components/Dialog';
import LoginForm from '../../components/LoginForm';
import useCurrentUser from '../../hooks/useCurrentUser';
import Copyright from '../../components/Copyright';
import { guestDashboard as guestDashboardPath } from '../../paths';
import history from '../../history';

export default () => {
  const userSession = useCurrentUser();
  const user = useStoreState(get('user.data'));
  const setUser = useStoreActions(get('user.set'));

  const handleOnClose = e => {
    e.preventDefault();
  };

  useEffect(() => {
    if (!userSession) return;

    if (!user) setUser(userSession);
    else history.push(guestDashboardPath);
  }, [userSession, user, setUser]);

  const isXsScreenSize = useMediaQuery(useTheme().breakpoints.down('xs'));

  return (
    <div>
      <CssBaseline />
      <Dialog onClose={handleOnClose} open fullScreen={isXsScreenSize}>
        <Box mx="auto" px={{ xs: 1, sm: 3 }} maxWidth={{ xs: 318, sm: 350 }}>
          <LoginForm />
        </Box>
        <Box mt={3}>
          <Copyright />
        </Box>
      </Dialog>
    </div>
  );
};
