import { get, getOr, find } from 'lodash/fp';
import { parseInt } from '../utils';

export default (guestInfo, limitRules) => {
  if (!limitRules) return 0;

  const isHomeless = parseInt(get('homeless', guestInfo)) === 1;
  if (isHomeless) {
    return find({ key: 'NR' }, limitRules).quantity;
  }

  const householdRule = find({ key: 'HH' }, limitRules);
  if (householdRule) {
    return householdRule.quantity;
  }

  const householdTotal = getOr(1, 'householdTotal', guestInfo);

  const rules = find(each => {
    const from = parseInt(each.from);
    return (
      householdTotal >= from &&
      (each.to === '+' || householdTotal <= parseInt(each.to))
    );
  }, limitRules);

  return getOr(0, 'quantity', rules);
};
