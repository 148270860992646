import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import HealthLevels from '../../constants/HealthLevels';
import HealthLevelLabels from '../../constants/HealthLevelLabels';

const HealthIndicator = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 8px;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #aaaaaa;
`;

const HealthLevelStyles = {
  [HealthLevels.GO]: { background: ' #22963d' },
  [HealthLevels.SLOW]: {
    background:
      'repeating-linear-gradient(45deg, #ede319, #ede319 2px, #a4a383 1px, #a4a383 3px)'
  },
  [HealthLevels.WHOA]: {
    backgroundColor: '#ffffff',
    backgroundSize: '3px 3px',
    backgroundImage: 'linear-gradient(0deg, #ffffff 50%, #da4d4a 50%)'
  }
};

const HealthLevelIndicator = ({ value, ...props }) => (
  <Tooltip title={HealthLevelLabels[value]} {...props}>
    <HealthIndicator style={HealthLevelStyles[value]} />
  </Tooltip>
);

HealthLevelIndicator.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default HealthLevelIndicator;
