import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

export default withTheme(styled(Box)`
  position: relative;
  padding: 20px 10px 10px;
  background-color: ${({ theme: { palette } }) => palette.common.lightGray};
  ${({ theme: { breakpoints } }) => `${breakpoints.down('sm')} {
    padding: 10px;
  }`};
`);
