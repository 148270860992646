import { get, replace, reduce } from 'lodash/fp';

const reduceWithKey = get('convert', reduce)({ cap: false });

export default (path, params) =>
  reduceWithKey(
    (url, value, key) => replace(`:${key}`, value, url),
    path,
    params
  );
