import React from 'react';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

export default withTheme(styled(props => (
  <Typography variant="caption" {...props} />
))`
  color: ${({ theme: { palette } }) => palette.common.gray};
`);
