import PropTypes from 'prop-types';
import LimitRulesType from './LimitRulesType';
import PicklistCategoryType from './PicklistCategoryType';
import PicklistItemType from './PicklistItemType';

export default PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  notes: PropTypes.string,
  position: PropTypes.number,
  trackingMethod: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string
  }),
  categories: PropTypes.arrayOf(PicklistCategoryType),
  items: PropTypes.arrayOf(PicklistItemType),
  limitRules: LimitRulesType,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string
});
