import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { get, map, flow, join } from 'lodash/fp';
import parse from 'html-react-parser';
import { withTheme } from '@material-ui/core/styles';
import {
  Card,
  Typography,
  CardActions,
  Button,
  CardActionArea
} from '@material-ui/core';
import ReservationType from '../../interfaces/ReservationType';
import pantryAddressLine from '../../helpers/pantryAddressLine';
import pagePathToUrl from '../../helpers/pagePathToUrl';
import { ON_HOLD } from '../../constants/ReservationStatus';
import { reservationOrder, reservationSummary } from '../../paths';
import ReservationStatus from '../ReservationStatus';
import CardHeader from './CardHeader';
import CardContent from './CardContent';
import IncompleteBackdrop from './IncompleteBackdrop';

import history from '../../history';

const StyledCard = withTheme(styled(Card)`
  box-shadow: none;
  position: relative;
  border: 1px solid ${get('theme.palette.common.lightGray')};
  .MuiCardActions-root {
    padding-left: 20px;
  }
  .MuiCardActions-spacing > button {
    margin-left: auto;
  }
`);

const programsToLabels = flow(map('name'), join(', '), parse);

function ReservationCard({ data }) {
  const { id, pantrySite, requestTime, status, meta } = data;
  const { programs = [] } = meta;
  const requestDate = moment(requestTime);

  const handleClick = () => {
    const params = { id, pantryPath: get('path', pantrySite) };
    if (status === ON_HOLD)
      history.push(pagePathToUrl(reservationOrder, params));
    else history.push(pagePathToUrl(reservationSummary, params));
  };

  return (
    <StyledCard>
      {status === ON_HOLD && <IncompleteBackdrop onClick={handleClick} />}
      <CardActionArea onClick={handleClick}>
        <CardHeader
          title={requestDate.format('MMM D, YYYY')}
          subheader={requestDate.format('dddd LT')}
        />
        <CardContent>
          <Typography variant="caption" component="p" gutterBottom>
            <strong>{programsToLabels(programs)}</strong>
          </Typography>
          <Typography component="h4">{get('name', pantrySite)}</Typography>
          <Typography variant="caption" component="address" gutterBottom>
            {pantryAddressLine(pantrySite)}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <ReservationStatus
          size="small"
          status={status}
          style={{ padding: 0, border: 'none' }}
        />
        <Button size="small" color="primary" onClick={handleClick}>
          VIEW
        </Button>
      </CardActions>
    </StyledCard>
  );
}

ReservationCard.propTypes = {
  data: ReservationType.isRequired
};

export default ReservationCard;
