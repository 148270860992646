import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  map,
  get,
  getOr,
  flow,
  isNull,
  sumBy,
  groupBy,
  debounce,
  sortBy
} from 'lodash/fp';
import { Box } from '@material-ui/core';
import { useStoreState } from 'easy-peasy';
import excludeZeroLimit from '../../helpers/excludeZeroLimit';
import filterIsVisible from '../../helpers/reservation/filterIsVisible';
import guestQuantityLimit from '../../helpers/guestQuantityLimit';
import PicklistType from '../../interfaces/PicklistType';
import Header from '../EventHeader';
import Content from '../EventContent';
import PicklistCategory from '../PicklistCategory';
import { PicklistBox, PicklistHelptext, NotesTextField } from './elements';

function Picklist({ data, order, onChange }) {
  const { id, name, categories, items, limitRules } = data;
  const orderItems = getOr([], 'items', order);
  const guestInfo = useStoreState(get('userDetails.data.otherInfo'));
  const hasLimit = !isNull(limitRules);

  const quantityLimit = useMemo(
    () => guestQuantityLimit(guestInfo, limitRules),
    [guestInfo, limitRules]
  );

  const filteredCategories = useMemo(
    () =>
      flow(
        filterIsVisible,
        list => excludeZeroLimit(guestInfo, list),
        sortBy('position')
      )(categories),
    [guestInfo, categories]
  );

  const itemsByCategory = useMemo(
    () =>
      flow(
        filterIsVisible,
        list => excludeZeroLimit(guestInfo, list),
        groupBy('categoryId')
      )(items),
    [guestInfo, items]
  );

  const addedQuantity = useMemo(() => {
    return sumBy('quantity', orderItems);
  }, [orderItems]);
  const picklistLimitReached = hasLimit && addedQuantity >= quantityLimit;

  const totalOrderQuantity = useMemo(() => {
    return sumBy('quantity', orderItems);
  }, [orderItems]);

  const debounceUpdateComment = debounce(800)(notes => {
    onChange({ ...order, notes });
  });

  const handleChangeComment = ({ target }) => {
    debounceUpdateComment(target.value);
  };

  const handleChangeOrderItems = value => {
    onChange({ ...order, items: value });
  };

  return (
    <PicklistBox mb={2}>
      <Header variant="h5" style={{ textAlign: 'left' }}>
        {get('trackingMethod.label', data)} - {name}{' '}
        {hasLimit && `(${quantityLimit - totalOrderQuantity})`}
      </Header>
      <Content style={{ paddingTop: 10 }}>
        {map(
          category => (
            <PicklistCategory
              data={category}
              key={`category-${category.id}`}
              picklistId={id}
              items={getOr([], category.id, itemsByCategory)}
              orderItems={getOr([], 'items', order)}
              picklistLimit={quantityLimit}
              picklistLimitReached={picklistLimitReached}
              onChangeOrderItems={handleChangeOrderItems}
            />
          ),
          filteredCategories
        )}
        <Box>
          {hasLimit && (
            <PicklistHelptext>
              You have{' '}
              <strong>
                {quantityLimit - totalOrderQuantity} items remaining
              </strong>
              . Choose additional items and hit continue when done.
            </PicklistHelptext>
          )}
          <NotesTextField
            label={data.notes}
            defaultValue={order.notes}
            onChange={handleChangeComment}
            variant="outlined"
            rows={5}
            multiline
          />
        </Box>
      </Content>
    </PicklistBox>
  );
}

Picklist.propTypes = {
  data: PicklistType.isRequired,
  onChange: PropTypes.func.isRequired,
  order: PropTypes.shape({
    picklistId: PropTypes.number,
    notes: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({}))
  })
};

Picklist.defaultProps = {
  order: {}
};

export default Picklist;
