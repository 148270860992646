import PropTypes from 'prop-types';
import NumberOrStringType from './NumberOrStringType';

export default PropTypes.shape({
  time: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  slots: PropTypes.number.isRequired,
  reservedSlots: PropTypes.number.isRequired,
  reservationIds: PropTypes.arrayOf(NumberOrStringType)
});
