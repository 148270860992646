import { set, getOr, flow, reduce } from 'lodash/fp';
import { parseInt } from '../../utils';
import getItemAvailabilityUniqKey from '../../inventory/getItemAvailabilityUniqKey';

export default (orders = []) =>
  flow(
    reduce((allItems, each) => [...allItems, ...each.items], []),
    reduce((sumByUniqKey, { inventorySnapshot, quantity }) => {
      if (!inventorySnapshot) return sumByUniqKey;
      const uniqKey = getItemAvailabilityUniqKey(inventorySnapshot);
      const sumQuantity = getOr(0, uniqKey, sumByUniqKey) + parseInt(quantity);
      return set(uniqKey, sumQuantity, sumByUniqKey);
    }, {})
  )(orders);
