import styled from 'styled-components';
import { Box, TextField, Button } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

export const ItemBox = withTheme(styled(Box)`
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 16px;
  > .MuiBox-root:first-child {
    width: 140px;
    padding: 16px 10px 10px;
    margin: 0 auto;
    text-align: center;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 2px solid rgba(0, 0, 0, 0.12);
    background-color: ${({ theme: { palette } }) => palette.common.white};
    .health-level {
      top: -3px;
      left: -3px;
      position: absolute;
    }
  }
  &.selected > .MuiBox-root:first-child {
    border: 2px solid ${({ theme: { palette } }) => palette.secondary.main};
  }
  &.selected > .MuiBox-root:last-child {
    > button {
      &:not(:disabled) {
        background: ${({ theme: { palette } }) => palette.secondary.main};
      }
      &:disabled {
        border-bottom: 2px solid
          ${({ theme: { palette } }) => palette.secondary.main};
        border-right: 2px solid
          ${({ theme: { palette } }) => palette.secondary.main};
      }
    }
    > .MuiTextField-root > .MuiInputBase-root {
      &::before {
        border-bottom: 2px solid
          ${({ theme: { palette } }) => palette.secondary.main};
      }
    }
  }
  &.error {
    margin-bottom: 30px;
    > .MuiBox-root:first-child {
      border: 2px solid ${({ theme: { palette } }) => palette.error.main};
    }
    > .MuiBox-root:last-child {
      > button:first-child {
        border-left: 2px solid ${({ theme: { palette } }) => palette.error.main};
        border-bottom: 2px solid
          ${({ theme: { palette } }) => palette.error.main};
      }
      > button:last-child {
        border-right: 2px solid
          ${({ theme: { palette } }) => palette.error.main};
        border-bottom: 2px solid
          ${({ theme: { palette } }) => palette.error.main};
      }
      > .MuiTextField-root > .MuiInputBase-root {
        &::before {
          border-bottom: 2px solid
            ${({ theme: { palette } }) => palette.error.main};
        }
      }
    }
    &::after {
      content: 'Out of Stock';
      width: 100%;
      bottom: -20px;
      display: block;
      color: ${({ theme: { palette } }) => palette.error.main};
      position: absolute;
    }
  }
`);

export const DeductButton = withTheme(styled(Button)`
  height: 29px;
  min-width: 50px;
  box-shadow: none;
  border-radius: 0;
  font-weight: bold;
  border-bottom-left-radius: 6px;
  color: ${({ theme: { palette } }) => palette.common.white};
  background: ${({ theme: { palette } }) => palette.primary.light};
  :hover {
    background: ${({ theme: { palette } }) => palette.secondary.light};
  }
`);

export const AddButton = withTheme(styled(Button)`
  height: 29px;
  min-width: 50px;
  box-shadow: none;
  border-radius: 0;
  font-weight: bold;
  border-bottom-right-radius: 6px;
  color: ${({ theme: { palette } }) => palette.common.white};
  background: ${({ theme: { palette } }) => palette.primary.light};
  :hover {
    background: ${({ theme: { palette } }) => palette.secondary.light};
  }
`);

export const QuantityInput = withTheme(styled(TextField)`
  width: 40px;
  > .MuiInputBase-root {
    &:before {
      border-bottom: 2px solid rgba(0, 0, 0, 0.12);
    }
    > .MuiInputBase-input {
      text-align: center;
      background: ${({ theme: { palette } }) => palette.common.white};
    }
  }
`);

export const ItemLimit = styled.small`
  top: 5px;
  right: 6px;
  color: #8d8181;
  font-size: 9px;
  position: absolute;
  text-transform: capitalize;
`;
