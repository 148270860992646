import PropTypes from 'prop-types';
import NumberOrStringType from './NumberOrStringType';

export default PropTypes.shape({
  item: PropTypes.shape({
    id: NumberOrStringType.isRequired,
    name: PropTypes.string.isRequired
  }),
  location: PropTypes.shape({
    id: NumberOrStringType.isRequired,
    name: PropTypes.string.isRequired
  }),
  zone: PropTypes.shape({
    id: NumberOrStringType.isRequired,
    name: PropTypes.string.isRequired
  })
});
