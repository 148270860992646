import React from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';

const StyledBox = styled(Box)`
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  padding-top: 20px;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  background-color: #efefef;
  > h4 {
    color: #666;
    transform: rotate(-30deg);
  }
`;

export default function IncompleteBackdrop(props) {
  return (
    <StyledBox {...props}>
      <Typography variant="h4">Full</Typography>
    </StyledBox>
  );
}
