/* eslint-disable no-param-reassign */
import { get, has, upperFirst } from 'lodash/fp';
import { actionOn, thunkOn } from 'easy-peasy';
import history from '../history';
import { guestEditProfile as guestEditProfilePath } from '../paths';

export default {
  onUserDetailsUpdate: thunkOn(
    get('userDetails.update'),
    async ({ user, notifications }, { result }) => {
      if (get('status', result) === 'error') {
        const errorMessage = has('error', result)
          ? 'Please check form error.'
          : get('message', result);
        notifications.showError({
          message: `Unable to save. ${errorMessage}`
        });
      } else {
        user.set(get('loginUser', result));
      }
    }
  ),
  onVerifyEmail: thunkOn(
    get('user.verifyEmail'),
    async ({ userDetails, notifications }, { payload, result }) => {
      const { type } = payload;
      if (get('status', result) === 'error') {
        notifications.showError({ message: get('message', result) });
      } else {
        userDetails.set(result);
        notifications.showSuccess({
          message: `${upperFirst(type)} Email successfully verified!`
        });
        history.push(guestEditProfilePath);
      }
    }
  ),
  onLoggedOut: actionOn(get('user.logOut'), state => {
    // Clear data
    state.user.data = null;
    state.userDetails.data = null;
    state.userReservations.data = null;
    state.userVisitedPantries.data = null;
    state.pantry.data = null;
    state.pantryPrograms.data = null;
    state.programsOutreaches.data = null;
    state.programsPicklists.data = null;
    state.reservation.data = null;
    state.reservationOrder.data = null;
    state.notifications.data = null;
    state.selectedEvent.data = null;
    state.inventory.itemsAvailabilityByUniqKey.data = null;
  })
};
