import React from 'react';
import { StoreProvider } from 'easy-peasy';
import { ThemeProvider } from '@material-ui/core/styles';
import Router from './Router';
import theme from '../provider/theme';
import store from '../configureStore';
import Notifications from '../components/Notifications';

export default () => (
  <ThemeProvider theme={theme}>
    <StoreProvider store={store}>
      <Router />
      <Notifications />
    </StoreProvider>
  </ThemeProvider>
);
