/* eslint-disable no-param-reassign */
import { action, thunk, computed } from 'easy-peasy';
import { get, size, keyBy, flow, reduce } from 'lodash/fp';
import { fetchProgramAvailableOutreaches } from '../../services/fbm-api';

export default {
  data: null,
  meta: null,
  error: null,
  loading: false,
  fetch: thunk(async (actions, payload) => {
    try {
      actions.setLoading(true);
      const response = await fetchProgramAvailableOutreaches(payload);
      actions.set(response);
    } catch (error) {
      actions.setError(error);
    } finally {
      actions.setLoading(false);
    }
  }),
  set: action((state, payload) => {
    const { items, meta } = payload;
    state.data = { ...(state.data || {}), ...keyBy('id', items) };
    state.meta = { ...meta, count: size(items) };
  }),
  clear: action(state => {
    state.data = null;
    state.meta = null;
  }),
  clearError: action(state => {
    state.error = null;
  }),
  setError: action((state, error) => {
    state.error = error;
  }),
  setLoading: action((state, loading) => {
    state.loading = loading;
  }),
  getSelectedProgramIdsForOutreach: computed(({ data }) => outreachId =>
    flow(
      get(`${outreachId}.trackingMethods`),
      reduce((all, { isMatch, id }) => (isMatch ? [...all, id] : all), [])
    )(data)
  )
};
