import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMediaQuery, useTheme, withTheme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Logo from '../WhiteLogo';

const StyledDialog = withTheme(styled(Dialog)`
  .MuiPaper-root {
    border-radius: 10px;
    ${({ theme: { breakpoints } }) => `${breakpoints.down('xs')} {
      border-radius: 0;
    }`};
    > .MuiDialogTitle-root {
      padding: 8px;
      text-align: center;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      color: ${({ theme: { palette } }) => palette.common.white};
      background-color: ${({ theme: { palette } }) => palette.secondary.main};
      ${({ theme: { breakpoints } }) => `${breakpoints.down('xs')} {
        border-radius: 0;
      }`};
      > .MuiTypography-root {
        line-height: 0.5;
      }
    }
    > .MuiDialogContent-root {
      padding: 20px;
      ${({ theme: { breakpoints } }) => `${breakpoints.down('xs')} {
        padding: 15px 0;
      }`};
    }
  }
`);

export default function CustomDialog({
  open,
  onClose,
  title,
  actions,
  children,
  ...props
}) {
  const theme = useTheme();
  const isXsScreenSize = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      fullScreen={isXsScreenSize}
      {...props}
    >
      <DialogTitle>
        {title || <Logo width={isXsScreenSize && 210} />}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </StyledDialog>
  );
}

CustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.node,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  actions: PropTypes.node
};

CustomDialog.defaultProps = {
  title: null,
  actions: null
};
