import React from 'react';
import { get } from 'lodash/fp';
import { useStoreState } from 'easy-peasy';
import { Redirect } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import Layout from '../../components/Layout';
import { login as loginPath } from '../../paths';
import useCurrentUser from '../../hooks/useCurrentUser';
import Form from './Form';
import PageLoading from '../../components/PageLoading';
import useLocationQuery from '../../hooks/useLocationQuery';
import PageHeading from '../../components/PageHeading';
import PageSection from '../../components/PageSection';

export default function GuestEditProfile() {
  const { pantryPath = '' } = useLocationQuery();
  const currentUser = useCurrentUser();
  const { data: userDetails, loading } = useStoreState(get('userDetails'));

  if (!currentUser) {
    return <Redirect to={loginPath} />;
  }

  return (
    <Layout>
      {!userDetails && loading ? (
        <PageLoading />
      ) : (
        <>
          {!get('firstName', userDetails) && (
            <Box mb={2} mt={1}>
              <Alert severity="info">
                <AlertTitle>Complete Your Profile</AlertTitle>
                Guest Details below are needed to complete your reservation.
              </Alert>
            </Box>
          )}
          <PageHeading>Guest Details</PageHeading>
          <PageSection>
            <Form pantryPath={pantryPath} userDetails={userDetails} />
          </PageSection>
        </>
      )}
    </Layout>
  );
}
