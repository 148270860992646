import React, { useState } from 'react';
import { get } from 'lodash/fp';
import { useStoreState } from 'easy-peasy';
import { useParams } from 'react-router-dom';
import {
  AppBar,
  Box,
  Toolbar,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { PhoneForwarded, Place } from '@material-ui/icons';
import Link from '@material-ui/core/Link';
import Logo from '../../GreyLogo';
import pagePathToUrl from '../../../helpers/pagePathToUrl';
import toGoogleMapLink from '../../../helpers/toGoogleMapLink';
import pantryAddressLine from '../../../helpers/pantryAddressLine';
import history from '../../../history';
import { pantryBasePath as pantryDashboardPath } from '../../../paths';
import MainNav from '../MainNav';
import PantryHeading from '../PantryHeading';
import PantryHeaderDetails from '../PantryHeaderDetails';
import useStyles from './styles';
import ContactDetailsDialog from '../../ContactDetailsDialog';

export default function PrimarySearchAppBar() {
  const classes = useStyles();
  const { pantryPath } = useParams();
  const pantry = useStoreState(get('pantry.data'));
  const pantryName = get('name', pantry);
  const contactName = get('contactName', pantry);
  const contactEmail = get('contactEmail', pantry);
  const contactNumber = get('contactNumber', pantry);
  const pantryAddress = pantry && pantryAddressLine(pantry);
  const isXsScreen = useMediaQuery(useTheme().breakpoints.down('xs'));
  const addressLabel = isXsScreen ? 'view address' : pantryAddress;

  const handleGoToPantry = () => {
    history.push(pagePathToUrl(pantryDashboardPath, { pantryPath }));
  };

  const [showContactDetails, setShowContactDetails] = useState(false);
  const handleClickOpen = () => {
    setShowContactDetails(true);
  };
  const handleOnCloseDialog = () => {
    setShowContactDetails(false);
  };

  const hasContactDetails = contactName || contactEmail || contactNumber;

  return (
    <div className={classes.grow}>
      <AppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          <Box>
            {pantryPath ? (
              <>
                <PantryHeading
                  onClick={handleGoToPantry}
                  className={classes.heading}
                >
                  {pantryName}
                </PantryHeading>
                <Link
                  target="_blank"
                  color="inherit"
                  href={toGoogleMapLink(pantryAddress)}
                >
                  <Place fontSize="small" className={classes.subHeaderIcon} />
                  <PantryHeaderDetails className={classes.subHeaderDetails}>
                    {addressLabel || '...'}
                  </PantryHeaderDetails>
                </Link>
                {hasContactDetails && (
                  <>
                    <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link color="inherit" onClick={handleClickOpen}>
                      <PhoneForwarded
                        fontSize="small"
                        className={classes.subHeaderIcon}
                      />
                      <PantryHeaderDetails className={classes.subHeaderDetails}>
                        Contact Us
                      </PantryHeaderDetails>
                    </Link>
                  </>
                )}
              </>
            ) : (
              <Logo width={140} mt={0.5} />
            )}
            {hasContactDetails && (
              <ContactDetailsDialog
                open={showContactDetails}
                onClose={handleOnCloseDialog}
              />
            )}
          </Box>
          <div className={classes.grow} />
          <Box>
            <MainNav />
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}
