import { get } from 'lodash/fp';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import { CardContent } from '@material-ui/core';

export default withTheme(styled(CardContent)`
  margin: 0 10px 0;
  padding: 10px 10px 10px;
  border: 1px solid ${get('theme.palette.common.lightGray')};
  background-color: ${get('theme.palette.common.lightGray')};
  > h4 {
    font-size: 18px;
    text-transform: uppercase;
    color: ${get('theme.palette.common.darkGray')};
  }
  > address {
    font-style: normal;
    color: ${get('theme.palette.primary.dark')};
  }
`);
