import React, { useState } from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { has, getOr, get } from 'lodash/fp';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useForm } from 'react-hook-form';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import useStyles from '../../provider/styles/form';
import { logIn } from '../../services/userAuth';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Must be a valid email')
    .required('Required field'),
  password: yup
    .string()
    .required('Required field')
    .min(8)
    .max(24)
});

function LoginForm({ showRegister }) {
  const { pantryPath } = useParams();
  const classes = useStyles();
  const defaultValues = { email: '', password: '' };
  const { register, handleSubmit, errors } = useForm({
    validationSchema,
    defaultValues
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const { loading: loadingUser } = useStoreState(get('user'));
  const setUser = useStoreActions(get('user.set'));
  const { showError } = useStoreActions(get('notifications'));
  const fetchUserDetails = useStoreActions(get('userDetails.fetch'));
  const fetchPantryPrograms = useStoreActions(get('pantryPrograms.fetch'));
  const { setShowLoginForm, setShowSignupForm } = useStoreActions(
    get('components.loginSignupDialog')
  );

  const handleClickRegister = () => {
    setShowLoginForm(false);
    setShowSignupForm(true);
  };

  const handleShowPassword = () => {
    setShowPassword(true);
  };

  const handleHidePassword = () => {
    setShowPassword(false);
  };

  const onSubmit = async ({ email, password }) => {
    try {
      setLoggingIn(true);
      const user = await logIn(email, password);
      setUser(user);
      fetchUserDetails(user.id);
      if (pantryPath) fetchPantryPrograms(pantryPath);
    } catch (err) {
      const defaultError = 'Invalid Email and/or Password!';
      showError({ message: getOr(defaultError, 'message', err) });
    } finally {
      setLoggingIn(false);
    }
  };

  const isLoading = loggingIn || loadingUser;
  const isError = field => has(field, errors);
  const getFieldError = field => {
    return getOr('', `${field}.message`, errors);
  };

  return (
    <Box mb={1}>
      <Typography variant="h6" align="center">
        LOG IN
      </Typography>
      <form
        noValidate
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              variant="outlined"
              required
              fullWidth
              size="small"
              name="email"
              label="Email Address"
              autoComplete="email"
              disabled={isLoading}
              inputRef={register}
              error={isError('email')}
              helperText={getFieldError('email')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel htmlFor="password" error={isError('password')}>
                Password *
              </InputLabel>
              <OutlinedInput
                required
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                disabled={isLoading}
                inputRef={register}
                error={isError('password')}
                label="Password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      aria-label="toggle password visibility"
                      onMouseDown={handleShowPassword}
                      onTouchStart={handleShowPassword}
                      onMouseUp={handleHidePassword}
                      onTouchEnd={handleHidePassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText error={isError('password')}>
                {getFieldError('password')}
              </FormHelperText>
            </FormControl>
            <Link
              variant="body2"
              to="/forgot-password"
              component={RouterLink}
              style={{ float: 'right', marginTop: 5 }}
            >
              Forgot password?
            </Link>
          </Grid>
        </Grid>
        <Button
          fullWidth
          type="submit"
          color="primary"
          variant="contained"
          className={classes.submit}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Log in'
          )}
        </Button>

        {showRegister && (
          <Box textAlign="right">
            <Button size="small" color="primary" onClick={handleClickRegister}>
              No account yet? Register
            </Button>
          </Box>
        )}
      </form>
    </Box>
  );
}

LoginForm.propTypes = {
  showRegister: PropTypes.bool
};

LoginForm.defaultProps = {
  showRegister: false
};

export default LoginForm;
