import React, { Fragment, useMemo, useEffect } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { get, map, isEmpty, flow, values, sortBy } from 'lodash/fp';
import { useStoreActions, useStoreState } from 'easy-peasy';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Event from '../../../../components/Event';

function ProgramsOutreaches({ programIds, reservedEventsById }) {
  const { pantryPath } = useParams();
  const selectedEvent = useStoreState(get('selectedEvent'));
  const { meta, data: outreaches, loading } = useStoreState(
    get('programsOutreaches')
  );
  const { fetch: fetchOutreaches } = useStoreActions(get('programsOutreaches'));
  const setReservation = useStoreActions(get('reservation.set'));

  const sortedOutreaches = useMemo(
    () =>
      flow(
        values,
        sortBy(({ date, startTime }) =>
          moment(`${date} ${startTime}`, 'YYYY-MM-DD hh:mm A').unix()
        )
      )(outreaches),
    [outreaches]
  );

  useEffect(() => {
    const { event, timeSlot } = selectedEvent;
    if (timeSlot) {
      const { id: outreachId, date } = event;
      const time = moment(timeSlot, 'LT').format('HH:mm:ss');
      setReservation({ outreachId, requestTime: `${date} ${time}` });
    } else {
      setReservation({ outreachId: '', requestTime: '' });
    }
  }, [selectedEvent]);

  const handleShowMore = () => {
    const page = meta.page + 1;
    fetchOutreaches({ pantryPath, programIds, page });
  };

  const hasOutreaches = !isEmpty(outreaches);
  const displayShowMore =
    !loading && hasOutreaches && get('count', meta) === get('limit', meta);

  return (
    <Grid container spacing={2}>
      {map(eventData => {
        const { id } = eventData;
        const reserved = get(id, reservedEventsById);
        return (
          <Fragment key={`outreach-${id}`}>
            <Grid item xs={12} sm={4} key={`col-1-${id}`}>
              <Event data={eventData} reserved={reserved} />
            </Grid>
          </Fragment>
        );
      }, sortedOutreaches)}
      <Grid item xs={12}>
        <Box textAlign="center" m={1}>
          {isEmpty(programIds) || loading ? (
            <CircularProgress size={20} />
          ) : (
            !hasOutreaches && (
              <Typography>
                No incoming events for selected help desired.
              </Typography>
            )
          )}
          {displayShowMore && (
            <Button
              size="small"
              color="primary"
              onClick={handleShowMore}
              endIcon={<ExpandMoreIcon />}
            >
              Show more
            </Button>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

ProgramsOutreaches.propTypes = {
  programIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  reservedEventsById: PropTypes.shape({}).isRequired
};

export default ProgramsOutreaches;
