import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { withTheme } from '@material-ui/core/styles';

export default withTheme(styled(Button)`
  &.MuiButton-root {
    color: ${({ theme: { palette } }) => palette.common.white};
    background-color: ${({ theme: { palette } }) => palette.error.main};
    &:hover {
      background-color: ${({ theme: { palette } }) => palette.error.dark};
    }
  }
`);
