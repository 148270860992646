import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';
import { ErrorBoundary } from '@sentry/react';
import { Box, useTheme } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useStoreActions, useStoreState } from 'easy-peasy';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from './Header';
import Footer from './Footer';
import useStyles from './styles';
import useCurrentUser from '../../hooks/useCurrentUser';
import LoginSignupDialog from '../LoginSignupDialog';

const erroFallback = (
  <Alert severity="error">
    <AlertTitle>Oops!</AlertTitle>
    Something went wrong. This Error has been reported.
  </Alert>
);

function Layout({ contentProps, ...props }) {
  const { children } = props;
  const classes = useStyles();
  const { palette } = useTheme();
  const userSession = useCurrentUser();
  const user = useStoreState(get('user.data'));
  const setUser = useStoreActions(get('user.set'));
  const fetchUserDetails = useStoreActions(get('userDetails.fetch'));

  useEffect(() => {
    if (!user && userSession) {
      setUser(userSession);
      fetchUserDetails(userSession.id);
    }
  }, []);

  return (
    <div>
      <CssBaseline />
      <Header />
      <main className={classes.content}>
        <Box
          padding={2}
          paddingTop={5}
          marginX="auto"
          maxWidth={1140}
          minHeight="85vh"
          justifyContent="center"
          style={{ background: palette.common.white }}
          {...contentProps}
        >
          <ErrorBoundary fallback={erroFallback}>{children}</ErrorBoundary>
        </Box>
      </main>
      <Footer />
      <LoginSignupDialog />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  contentProps: PropTypes.shape({})
};

Layout.defaultProps = {
  contentProps: {}
};

export default Layout;
