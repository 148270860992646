/* eslint-disable no-param-reassign */
import { action } from 'easy-peasy';
import ordersToItemsSumByUniqKey from '../../../helpers/reservation/ordersToItemsSumByUniqKey';

export default {
  itemsSumQuantityByUniqKey: null,
  itemsInitialSumQuantityByUniqKey: null,
  updateItemsSumQuantityForOrders: action((state, orders) => {
    state.itemsSumQuantityByUniqKey = ordersToItemsSumByUniqKey(orders);
  }),
  updateInitialItemsSumQuantityForOrders: action((state, orders) => {
    state.itemsInitialSumQuantityByUniqKey = ordersToItemsSumByUniqKey(orders);
  }),
  clearItemsSumQuantityForOrders: action(state => {
    state.itemsSumQuantityByUniqKey = null;
  }),
  clearItemsInitialSumQuantityForOrders: action(state => {
    state.itemsInitialSumQuantityByUniqKey = null;
  })
};
