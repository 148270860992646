import {
  ON_HOLD,
  PENDING,
  ACTIVE,
  CONFIRMED,
  COMPLETED,
  CANCELED,
  REJECTED
} from './ReservationStatus';

export default {
  [ON_HOLD]: 'secondary',
  [PENDING]: 'primary',
  [ACTIVE]: 'primary',
  [CONFIRMED]: 'primary',
  [COMPLETED]: 'primary',
  [CANCELED]: 'secondary',
  [REJECTED]: 'secondary'
};
