import React from 'react';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import { Box, TextField, Typography } from '@material-ui/core';

export const PicklistBox = withTheme(styled(props => <Box {...props} />)`
  padding: 8px;
  border-radius: 6px;
  border: 2px solid ${({ theme: { palette } }) => palette.common.lightGray};
  background-color: ${({ theme: { palette } }) => palette.common.white};
  &.reserved > h5 {
    background-color: #ccc;
  }
  &.selected {
    border-color: ${({ theme: { palette } }) => palette.secondary.main};
  }
  &.selected > h5 {
    background-color: ${({ theme: { palette } }) => palette.secondary.main};
  }
`);

export const PicklistHelptext = withTheme(styled(props => (
  <Typography {...props} paragraph />
))`
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: ${({ theme: { palette } }) => palette.common.white};
  background-color: ${({ theme: { palette } }) => palette.secondary.main};
`);

export const NotesTextField = withTheme(styled(props => (
  <TextField {...props} />
))`
  width: 100%;
  background-color: ${({ theme: { palette } }) => palette.common.white};
  > MuiFormLabel-root {
    text-transform: uppercase;
  }
`);
