import React from 'react';
import { get, getOr, isEmpty } from 'lodash/fp';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Alert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';

export default props => {
  const data = useStoreState(get('notifications.data'));
  const clear = useStoreActions(get('notifications.remove'));
  const handleOnClose = (_, reason) => {
    if (reason === 'clickaway') return;
    clear();
  };

  if (isEmpty(data)) return '';

  const { message, type } = data;

  return (
    <Snackbar
      open
      onClose={handleOnClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={getOr(6000, 'autoHideDuration', props)}
      {...props}
    >
      <Alert onClose={handleOnClose} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
};
