import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const ProgramFormControlLabel = withTheme(styled(FormControlLabel)`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  border: 2px solid #ddd;
  background-color: ${({ theme: { palette } }) => palette.primary.light};
  &.selected {
    border: 2px solid ${({ theme: { palette } }) => palette.secondary.main};
    background-color: ${({ theme: { palette } }) => palette.secondary.main};
  }
  > .MuiFormControlLabel-label {
    width: 100%;
    height: 100%;
    padding: 10px;
    min-height: 42px;
    background-color: ${({ theme: { palette } }) => palette.common.white};
    border-start-end-radius: 4px;
    border-end-end-radius: 4px;
    ${({ theme: { breakpoints } }) => `${breakpoints.down('sm')} {
      font-size: 14px;
    }`};
  }
  > .MuiCheckbox-root,
  > .MuiCheckbox-colorSecondary.Mui-checked {
    color: ${({ theme: { palette } }) => palette.common.white};
  }
`);

const Description = withTheme(styled.div`
  margin: 5px 0 0;
  font-size: 12px;
  color: ${({ theme: { palette } }) => palette.common.gray};
  ${({ theme: { breakpoints } }) => `${breakpoints.down('sm')} {
    font-size: 11px;
  }`};
  > p {
    margin: 0;
  }
`);

const ProgramFormControl = ({
  program: { id, name, description },
  checked,
  ...props
}) => (
  <ProgramFormControlLabel
    value={id}
    checked={checked}
    className={checked && 'selected'}
    control={<Checkbox name="program" value={id} />}
    label={
      <>
        {parse(name)}
        {description && <Description>{parse(description)}</Description>}
      </>
    }
    {...props}
  />
);

ProgramFormControl.propTypes = {
  program: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string
  }).isRequired,
  checked: PropTypes.bool.isRequired
};

export default ProgramFormControl;
