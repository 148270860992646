import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import LoginOrSignup from '../../pages/LoginOrSignup';
import CheckAuth from '../../pages/CheckAuth';
import EmailVerified from '../../pages/EmailVerified';
import EmailVerification from '../../pages/EmailVerification';
import PantryDashboard from '../../pages/Pantry/Dashboard';
import ReservationSummary from '../../pages/ReservationSummary';
import ReservationOrder from '../../pages/ReservationOrder';
import ForgotPassword from '../../pages/ForgotPassword';
import ResetPasswordVerification from '../../pages/ResetPasswordVerification';
import GuestEditProfile from '../../pages/GuestEditProfile';
import GuestDashboard from '../../pages/GuestDashboard';
import history from '../../history';
import * as paths from '../../paths';

export default () => (
  <Router history={history}>
    <Switch>
      <Route exact path={paths.home} component={LoginOrSignup} />
      <Route exact path={paths.login} component={LoginOrSignup} />
      <Route exact path={paths.checkAuth} component={CheckAuth} />
      <Route exact path={paths.emailVerified} component={EmailVerified} />
      <Route
        exact
        path={paths.emailVerification}
        component={EmailVerification}
      />
      <Route exact path={paths.forgotPassword} component={ForgotPassword} />
      <Route
        exact
        path={paths.resetPasswordVerification}
        component={ResetPasswordVerification}
      />

      <Route exact path={paths.pantryBasePath} component={PantryDashboard} />
      <Route
        exact
        path={paths.reservationSummary}
        component={ReservationSummary}
      />
      <Route exact path={paths.reservationOrder} component={ReservationOrder} />

      <Route exact path={paths.guestDashboard} component={GuestDashboard} />
      <Route exact path={paths.guestEditProfile} component={GuestEditProfile} />
    </Switch>
  </Router>
);
