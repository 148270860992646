import apiRequest from './api-request';

export const registerUser = async ({ email, password }) => {
  const response = await apiRequest('users/register', {
    method: 'POST',
    body: { email, password }
  });

  return response;
};

export const loginUser = async ({ email, password }) => {
  const response = await apiRequest('users/login', {
    method: 'POST',
    body: { email, password }
  });

  return response;
};

export const requestUserEmailVerificationCode = async ({ userId, type }) => {
  const response = await apiRequest(
    `users/${userId}/request-email-verification?type=${type}`,
    { method: 'POST' }
  );

  return response;
};

export const verifyUserEmail = async ({ userId, code, type }) => {
  const response = await apiRequest(
    `users/${userId}/verify-email/${code}?type=${type}`,
    {
      method: 'POST'
    }
  );

  return response;
};

export const requestUserResetPassword = async email => {
  const response = await apiRequest('users/request-reset-password', {
    method: 'POST',
    body: { email }
  });

  return response;
};

export const verifyUserResetPassword = async ({ email, code, password }) => {
  const response = await apiRequest('users/verify-reset-password', {
    method: 'POST',
    body: { email, code, password }
  });

  return response;
};

export const fetchUser = async id => {
  const response = await apiRequest(`users/${id}`);
  return response;
};

export const updateUser = async ({
  id,
  body: { firstName, lastName, email, loginEmail, ...otherInfo }
}) => {
  const response = await apiRequest(`users/${id}`, {
    method: 'PUT',
    body: { firstName, lastName, email, loginEmail, otherInfo }
  });

  return response;
};

export const fetchUserReservations = async id => {
  const response = await apiRequest(`users/${id}/reservations`);

  return response;
};

export const fetchUserVisitedPantries = async id => {
  const response = await apiRequest(`users/${id}/visited-pantries`);

  return response;
};

export const deleteUserVisitedPantry = async ({ userId, pantrySiteId }) => {
  const response = await apiRequest(
    `users/${userId}/visited-pantries/${pantrySiteId}`,
    { method: 'DELETE' }
  );

  return response;
};

export const fetchPantry = async pantryPath => {
  const response = await apiRequest(pantryPath);
  return response;
};

export const fetchPantryPrograms = async pantryPath => {
  const response = await apiRequest(`${pantryPath}/programs`);
  return response;
};

export const fetchProgramAvailableOutreaches = async ({
  pantryPath,
  programIds: programId,
  limit = 6,
  page = 1
}) => {
  const response = await apiRequest(
    `${pantryPath}/program-upcoming-outreaches`,
    { qs: { programId, limit, page } }
  );
  return response;
};

export const fetchProgramPicklsits = async ({
  pantryPath,
  programIds: programId
}) => {
  const response = await apiRequest(`${pantryPath}/program-picklists`, {
    qs: { programId }
  });
  return response;
};

export const createReservation = async ({ pantryPath, body }) => {
  const response = await apiRequest(`${pantryPath}/reservations`, {
    method: 'POST',
    body
  });

  return response;
};

export const updateReservation = async ({ pantryPath, id, body }) => {
  const response = await apiRequest(`${pantryPath}/reservations/${id}`, {
    method: 'PUT',
    body
  });

  return response;
};

export const createReservationOrder = async ({ pantryPath, id, body }) => {
  const response = await apiRequest(`${pantryPath}/reservations/${id}/order`, {
    method: 'POST',
    body
  });

  return response;
};

export const updateReservationOrder = async ({ pantryPath, id, body }) => {
  const response = await apiRequest(`${pantryPath}/reservations/${id}/order`, {
    method: 'PUT',
    body
  });

  return response;
};

export const deleteReservation = async ({ pantryPath, id }) => {
  const response = await apiRequest(`${pantryPath}/reservations/${id}`, {
    method: 'DELETE'
  });

  return response;
};

export const fetchReservation = async ({ pantryPath, id }) => {
  const response = await apiRequest(`${pantryPath}/reservations/${id}`);
  return response;
};

export const fetchReservationPicklsits = async ({ pantryPath, id }) => {
  const response = await apiRequest(
    `${pantryPath}/reservations/${id}/picklists`
  );
  return response;
};

export const updateReservationStatus = async ({
  pantryPath,
  id,
  statusAction
}) => {
  const response = await apiRequest(
    `${pantryPath}/reservations/${id}/status/${statusAction}`,
    { method: 'PUT' }
  );
  return response;
};

export const updateReservationUpdatedAt = async ({ pantryPath, id }) => {
  const response = await apiRequest(
    `${pantryPath}/reservations/${id}/updated-at`,
    { method: 'PUT' }
  );
  return response;
};

export const fetchInventoryItemAvailability = async params => {
  const { pantryPath, itemId, locationId, zoneId } = params;
  const response = await apiRequest(
    `${pantryPath}/inventory/items/${itemId}/availabilities/${locationId}/${zoneId}`
  );
  return response;
};

export const fetchInventoryPackageAvailability = async params => {
  const { pantryPath, itemId, locationId } = params;
  const response = await apiRequest(
    `${pantryPath}/inventory/packages/${itemId}/availabilities/${locationId}`
  );
  return response;
};
