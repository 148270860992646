/* eslint-disable no-param-reassign */
import { action, thunk } from 'easy-peasy';
import {
  registerUser,
  loginUser,
  requestUserEmailVerificationCode,
  verifyUserEmail,
  requestUserResetPassword,
  verifyUserResetPassword
} from '../../services/fbm-api';
import { logOut } from '../../services/userAuth';
import { setCurrentUser } from '../../provider/currentUser';

export default {
  data: null,
  loading: false,
  error: null,
  register: thunk(async (actions, payload) => {
    // not being used
    try {
      actions.setLoading(true);
      const response = await registerUser(payload);
      actions.set(response);
    } catch (error) {
      actions.setError(error);
    } finally {
      actions.setLoading(false);
    }
  }),
  login: thunk(async (actions, payload) => {
    // not being used
    try {
      actions.setLoading(true);
      const response = await loginUser(payload);
      actions.set(response);
    } catch (error) {
      actions.setError(error);
    } finally {
      actions.setLoading(false);
    }
  }),
  requestEmailVerificationCode: thunk(async (actions, { userId, type }) => {
    try {
      actions.setLoading(true);
      await requestUserEmailVerificationCode({ userId, type });
    } catch (error) {
      actions.setError(error);
    } finally {
      actions.setLoading(false);
    }
  }),
  verifyEmail: thunk(async (actions, payload) => {
    try {
      actions.setLoading(true);
      const response = await verifyUserEmail(payload);
      actions.set(response.loginUser);
      return response;
    } catch (error) {
      actions.setError(error);
      return error;
    } finally {
      actions.setLoading(false);
    }
  }),
  requestResetPassword: thunk(async (actions, email) => {
    try {
      actions.setLoading(true);
      await requestUserResetPassword(email);
    } catch (error) {
      actions.setError(error);
    } finally {
      actions.setLoading(false);
    }
  }),
  verifyResetPassword: thunk(async (actions, payload) => {
    try {
      actions.setLoading(true);
      const response = await verifyUserResetPassword(payload);
      actions.set(response);
    } catch (error) {
      actions.setError(error);
    } finally {
      actions.setLoading(false);
    }
  }),
  set: action((state, payload) => {
    state.data = payload;
    setCurrentUser(payload);
  }),
  logOut: action(async state => {
    state.data = null;
    logOut();
  }),
  setLoading: action((state, loading) => {
    state.loading = loading;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  clearError: action(state => {
    state.error = null;
  })
};
