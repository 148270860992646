import React from 'react';
import { get } from 'lodash/fp';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

const StyledBox = withTheme(styled(Box)`
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  color: ${get('theme.palette.common.white')};
  background-color: rgba(${get('theme.palette.secondary.mainRgb')}, 0.85);
  > h5 {
    margin-top: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  > h6 {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    padding: 30px 20px;
    border-radius: 60px;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 1px;
    color: ${get('theme.palette.secondary.main')};
    background: ${get('theme.palette.common.white')};
    border: 1px solid ${get('theme.palette.common.white')};
  }
`);

export default function IncompleteBackdrop(props) {
  return (
    <StyledBox {...props}>
      <Typography variant="h5">Reservation is Incomplete</Typography>
      <Typography variant="h6">Tap to fix</Typography>
    </StyledBox>
  );
}
