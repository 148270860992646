import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash/fp';
import parse from 'html-react-parser';
import { withTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { EventTrackingMethodType } from '../../interfaces/EventType';

const ProgramsLabel = withTheme(styled(Typography)`
  font-size: 13px;
  margin-top: 15px;
  ${({ theme: { breakpoints } }) => `${breakpoints.down('sm')} {
    font-size: 11px;
    margin-top: 10px;
  }`};
  > span {
    &:not(:last-child):after {
      content: ', ';
    }
    &.matched {
      font-weight: 600;
    }
  }
`);

const EventPrograms = ({ data, ...props }) => {
  const sortedPrograms = sortBy('label', data);
  return (
    <ProgramsLabel {...props}>
      {sortedPrograms.map(({ label, isMatch = false }) => (
        <span key={label} className={isMatch ? 'matched' : ''}>
          {parse(label)}
        </span>
      ))}
    </ProgramsLabel>
  );
};

EventPrograms.propTypes = {
  data: PropTypes.arrayOf(EventTrackingMethodType).isRequired
};

export default EventPrograms;
