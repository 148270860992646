import React, { useState } from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import { get, has, getOr, isNull } from 'lodash/fp';
import { useForm } from 'react-hook-form';
import { useStoreActions } from 'easy-peasy';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
  TextField,
  FormHelperText,
  CircularProgress,
  Typography
} from '@material-ui/core';
import { signUp, logIn } from '../../services/userAuth';
import useStyles from '../../provider/styles/form';
import useLocationQuery from '../../hooks/useLocationQuery';
import {
  home as homePath,
  login as loginPath,
  guestDashboard as guestDashboardPath
} from '../../paths';
import history from '../../history';

const { REACT_APP_CAPTCHA_SITE_KEY } = process.env;

export const passwordValidation = yup
  .string()
  .required('Required field')
  .min(8, 'Password must be at least 8 charaters')
  .matches(
    /^(?=.*[a-z ])(?=.*[A-Z0-9~`'"!@#$%^&*()-_+={}[\]|;:<>,./?]+).*$/,
    'Password must contain at least one Uppercase or Number or Special Character'
  );

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Must be a valid email')
    .required('Required field'),
  password: passwordValidation,
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
});

function SignupForm({ showLogin }) {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { pantryPath = '', ...reservationData } = useLocationQuery();
  const isFromReservation = pantryPath && has('outreachId', reservationData);
  const submitButtonLabel = isFromReservation ? 'Continue' : 'Submit';

  const [captcha, setCaptcha] = useState(null);
  const [loading, setLoading] = useState(false);
  const setUser = useStoreActions(get('user.set'));
  const fetchUserDetails = useStoreActions(get('userDetails.fetch'));
  const { showError, showInfo } = useStoreActions(get('notifications'));
  const { setShowLoginForm, setShowSignupForm } = useStoreActions(
    get('components.loginSignupDialog')
  );
  const { register, handleSubmit, errors, formState } = useForm({
    validationSchema
  });

  const handleClickLogin = () => {
    setShowSignupForm(false);
    setShowLoginForm(true);
  };

  const onSubmit = async formData => {
    if (isNull(captcha)) return;

    try {
      setLoading(true);
      const { email, password } = formData;
      const user = await signUp(email, password);
      await logIn(email, password);
      await setUser(user);
      fetchUserDetails(user.id);
      showInfo({
        message:
          'Your Account is created now we need you to fill out your profile to continue.!'
      });
      if (pathname === homePath || pathname === loginPath) {
        history.push(guestDashboardPath);
      }
    } catch (error) {
      const defaultError = 'Unable to register. Please try again.';
      const errMessage = get('error.0.message', error) || get('message', error);
      showError({ message: errMessage || defaultError });
      setLoading(false);
    }
  };

  const handleCaptchChange = value => {
    setCaptcha(value);
  };

  const isError = field => has(field, errors);
  const getFieldError = field => {
    return getOr('', `${field}.message`, errors);
  };

  return (
    <Box mb={{ xs: 1, sm: 2 }}>
      <Typography variant="h6" align="center">
        CREATE ACCOUNT
      </Typography>
      <form
        className={classes.form}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {isFromReservation && (
              <FormHelperText className={classes.helpText}>
                Your username will be your email - enter your email.
              </FormHelperText>
            )}
            <TextField
              required
              fullWidth
              size="small"
              name="email"
              label="Username (Email Address)"
              variant="outlined"
              autoComplete="username"
              inputRef={register}
              error={isError('email')}
              helperText={getFieldError('email')}
            />
          </Grid>
          {/* <Grid item xs={12}>
          {isFromReservation && (
            <FormHelperText className={classes.helpText}>
              Your primary phone number
            </FormHelperText>
          )}
          <TextField
            required
            fullWidth
            name="phoneNumber"
            label="Phone Number"
            variant="outlined"
            inputRef={register}
            error={isError('phoneNumber')}
            helperText={getFieldError('phoneNumber')}
          />
        </Grid> */}
          <Grid item xs={12}>
            {isFromReservation && (
              <FormHelperText className={classes.helpText}>
                Now we need to know the password you desire to use. You need to
                enter it in twice to make sure you type it in correctly.
              </FormHelperText>
            )}
            <TextField
              required
              fullWidth
              size="small"
              name="password"
              label="Password"
              type="password"
              variant="outlined"
              autoComplete="current-password"
              inputRef={register}
              error={isError('password')}
              helperText={getFieldError('password')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              size="small"
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              variant="outlined"
              autoComplete="current-password"
              inputRef={register}
              error={isError('confirmPassword')}
              helperText={getFieldError('confirmPassword')}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box mt={2} mb={1}>
            <ReCAPTCHA
              sitekey={REACT_APP_CAPTCHA_SITE_KEY}
              onChange={handleCaptchChange}
            />
          </Box>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading || !formState.dirty || isNull(captcha)}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              submitButtonLabel
            )}
          </Button>
          {showLogin && (
            <Grid item>
              <Button size="small" color="primary" onClick={handleClickLogin}>
                Already have an account? Sign in
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
    </Box>
  );
}

SignupForm.propTypes = {
  showLogin: PropTypes.bool
};

SignupForm.defaultProps = {
  showLogin: false
};

export default SignupForm;
