import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  helpText: {
    marginBottom: '10px'
  },
  error: {
    marginBottom: theme.spacing(1)
  },
  avatar: {
    margin: '20px auto 10px',
    backgroundColor: theme.palette.primary.light
  },
  paper: {
    padding: 20,
    borderRadius: 10,
    margin: '20px auto',
    border: `1px solid ${theme.palette.common.lightGray}`,
    backgroundColor: theme.palette.common.white
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(1.5, 0, 1)
  }
}));

export default useStyles;
