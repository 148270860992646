import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import signOnLogo from '../../assets/images/soxboxSignOnLogo.svg';

const Logo = ({ width, ...props }) => {
  return (
    <Box textAlign="center" {...props}>
      <img src={signOnLogo} alt="Sox Box" width={width} />
    </Box>
  );
};

Logo.propTypes = {
  width: PropTypes.number
};

Logo.defaultProps = {
  width: 250
};

export default Logo;
