import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { get, map, isEmpty, sortBy } from 'lodash/fp';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ReservationCard from '../../../components/ReservationCard';
import ReservationType from '../../../interfaces/ReservationType';

function Reservations({ data = [] }) {
  const sortedData = useMemo(() => sortBy('requestTime', data), [data]);
  return (
    <Grid container spacing={2}>
      {map(
        each => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={`reservation-${get('id', each)}`}
          >
            <ReservationCard data={each} />
          </Grid>
        ),
        sortedData
      )}
      {isEmpty(data) && (
        <Grid item xs={12}>
          <Typography align="center">No reservations yet.</Typography>
        </Grid>
      )}
    </Grid>
  );
}

Reservations.propTypes = {
  data: PropTypes.arrayOf(ReservationType).isRequired
};

export default Reservations;
