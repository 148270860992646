import React from 'react';
import { get } from 'lodash/fp';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import { CardHeader } from '@material-ui/core';

export default withTheme(styled(props => <CardHeader {...props} />)`
  padding: 10px 10px;
  margin: 10px 10px 0;
  font-weight: 600;
  text-transform: uppercase;
  color: ${get('theme.palette.common.white')};
  background-color: ${get('theme.palette.primary.light')};
  .MuiCardHeader-title {
    font-size: 34px;
    font-weight: 800;
    margin-bottom: -6px;
    ${({ theme }) => `${theme.breakpoints.down('xs')} {
      font-size: 32px;
    }`};
  }
  .MuiCardHeader-subheader {
    font-size: 22px;
    font-weight: 600;
    color: ${get('theme.palette.common.white')};
    margin-bottom: -3px;
    ${({ theme }) => `${theme.breakpoints.down('xs')} {
      font-size: 20px;
    }`};
  }
`);
