import React, { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import {
  get,
  has,
  getOr,
  omit,
  isEmpty,
  forEach,
  upperFirst,
  includes
} from 'lodash/fp';
import { useForm } from 'react-hook-form';
import { useStoreActions, useStoreState } from 'easy-peasy';
import {
  Box,
  Button,
  TextField,
  Grid,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputLabel,
  InputAdornment,
  FormHelperText
} from '@material-ui/core';
import useStyles from '../../../provider/styles/form';
import { reservationOrder as reservationOrderPath } from '../../../paths';
import history from '../../../history';
import UserDetailsType from '../../../interfaces/UserDetailsType';
import { getCurrentUser } from '../../../provider/currentUser';
import pagePathToUrl from '../../../helpers/pagePathToUrl';
import { LOGIN, CONTACT } from '../../../constants/UserEmailTypes';
import useLocationQuery from '../../../hooks/useLocationQuery';
import EmailIconWithVerifiedBadge from '../../../components/EmailIconWithVerifiedBadge';
import VerifyEmailButton from '../../../components/VerifyEmailButton';

const createAddressValidation = () => ({
  is: '0',
  then: yup.string().required('Required field'),
  otherwise: yup.string().notRequired()
});

const createCotactNumberWhenValidation = () => ({
  is: (val1, val2) => val1 === '' && val2 === '',
  then: yup.string().required('Required field'),
  otherwise: yup.string().notRequired()
});

const createContactNumberValidation = () =>
  yup
    .string()
    .test('len', 'Maximum of 20 characters', ({ length }) => length <= 20);

const validationSchema = yup.object().shape({
  firstName: yup.string().required('Required field'),
  lastName: yup.string().required('Required field'),
  homeless: yup.string().required('Required field'),
  streetAddress: yup.string().when('homeless', createAddressValidation()),
  apartment: yup.string(),
  city: yup.string().when('homeless', createAddressValidation()),
  state: yup.string().when('homeless', createAddressValidation()),
  zipCode: yup
    .string()
    .when('homeless', createAddressValidation())
    .matches(/^[a-zA-Z0-9 ]{1,9}$/, {
      message:
        'Only accepts numbers, letters and spaces - maximum of 9 characters',
      excludeEmptyString: true
    }),
  county: yup.string(),
  loginEmail: yup
    .string()
    .email('Must be a valid email')
    .required('Required field'),
  email: yup
    .string()
    .email('Must be a valid email')
    .required('Required field'),
  homePhone: createContactNumberValidation().when(
    ['workPhone', 'cellPhone'],
    createCotactNumberWhenValidation()
  ),
  workPhone: createContactNumberValidation(),
  cellPhone: createContactNumberValidation(),
  householdTotal: yup
    .number()
    .integer()
    .required('Required field')
    .typeError('Required field')
    .min(1)
    .max(50)
});

const composeDefaultFormValue = ({ loginEmail, userDetails }) => ({
  loginEmail,
  email: getOr(loginEmail, 'email', userDetails),
  firstName: getOr('', 'firstName', userDetails),
  lastName: getOr('', 'lastName', userDetails),
  ...getOr({}, 'otherInfo', userDetails)
});

const fieldServerError = (field, error = {}) => {
  const serverError = getOr('', 'types.message', error);
  const replacePrefix = includes(field, ['email', 'loginEmail'])
    ? `Field ${field} is `
    : `Field ${field} `;
  return upperFirst(serverError.replace(replacePrefix, ''));
};

const Form = ({ pantryPath, userDetails }) => {
  const classes = useStyles();
  const userSession = getCurrentUser();
  const [loading, setLoading] = useState(false);
  const userDetailsError = useStoreState(get('userDetails.error'));
  const updateUserDetails = useStoreActions(get('userDetails.update'));
  const updateReservation = useStoreActions(get('reservation.update'));
  const { showSuccess } = useStoreActions(get('notifications'));

  const query = useLocationQuery();
  const loginEmail = get('email', userSession);
  const reservationId = get('reservationId', query);
  const submitButtonLabel = reservationId ? 'Continue' : 'Submit';
  const defaultValues = useMemo(
    () => composeDefaultFormValue({ loginEmail, userDetails }),
    [userDetails]
  );

  const homeless = getOr('0', 'otherInfo.homeless', userDetails);
  const defaultHomeless = useMemo(() => homeless, []);
  const isVerifiedLoginEmail = !!get('verifiedAt', userSession);
  const isVerifiedContactEmail = !!get('verifiedAt', userDetails);
  const readOnlyEmails = !!reservationId;

  const {
    register,
    handleSubmit,
    watch,
    errors,
    formState,
    setError
  } = useForm({
    validationSchema,
    defaultValues
  });

  useEffect(() => {
    const formError = get('error', userDetailsError);
    if (!isEmpty(formError)) {
      forEach(({ field, message }) => {
        setError(field, { type: 'serverError', message });
      }, formError);
    }
  }, [userDetailsError]);

  const onSubmit = async formData => {
    setLoading(true);
    const result = await updateUserDetails({
      id: userDetails.id,
      body: formData
    });
    if (get('status', result) !== 'error') {
      showSuccess({ message: 'Profile successfully updated!' });
      if (pantryPath && reservationId) {
        const postData = {
          pantryPath,
          id: reservationId,
          body: omit('loginEmail', formData)
        };
        await updateReservation(postData);
        const pathParams = { pantryPath, id: reservationId };
        history.push(pagePathToUrl(reservationOrderPath, pathParams));
      }
    }
    setLoading(false);
  };

  const isError = field => has(field, errors);
  const getFieldError = field => {
    const fieldError = get(field, errors);
    return get('message', fieldError) || fieldServerError(field, fieldError);
  };

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            name="loginEmail"
            label="Login Email"
            variant="outlined"
            inputRef={register}
            error={isError('loginEmail')}
            helperText={getFieldError('loginEmail')}
            InputProps={{
              readOnly: readOnlyEmails,
              startAdornment: (
                <InputAdornment position="start">
                  {!readOnlyEmails && (
                    <EmailIconWithVerifiedBadge
                      verified={isVerifiedLoginEmail}
                    />
                  )}
                </InputAdornment>
              ),
              endAdornment: !isVerifiedLoginEmail && (
                <InputAdornment position="end">
                  {readOnlyEmails ? (
                    'read only'
                  ) : (
                    <VerifyEmailButton label="Verify Email" type={LOGIN} />
                  )}
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            name="email"
            label="Contact Email"
            variant="outlined"
            inputRef={register}
            error={isError('email')}
            helperText={getFieldError('email')}
            InputProps={{
              readOnly: readOnlyEmails,
              startAdornment: (
                <InputAdornment position="start">
                  {!readOnlyEmails && (
                    <EmailIconWithVerifiedBadge
                      verified={isVerifiedContactEmail}
                    />
                  )}
                </InputAdornment>
              ),
              endAdornment: !isVerifiedContactEmail && (
                <InputAdornment position="end">
                  {readOnlyEmails ? (
                    'read only'
                  ) : (
                    <VerifyEmailButton label="Verify Email" type={CONTACT} />
                  )}
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            name="firstName"
            label="First Name"
            variant="outlined"
            inputRef={register}
            error={isError('firstName')}
            helperText={getFieldError('firstName')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            name="lastName"
            label="Last Name"
            variant="outlined"
            inputRef={register}
            error={isError('lastName')}
            helperText={getFieldError('lastName')}
          />
        </Grid>
        <Grid item xs={12}>
          <Box marginTop={1} marginBottom={-1}>
            <RadioGroup
              row
              aria-label="position"
              defaultValue={defaultHomeless}
            >
              <InputLabel
                style={{
                  marginTop: '12px',
                  fontWeight: 'bold',
                  marginRight: '20px'
                }}
              >
                Do you have a physical address?
              </InputLabel>
              <FormControlLabel
                value="0"
                label="Yes"
                control={
                  <Radio name="homeless" color="primary" inputRef={register} />
                }
              />
              <FormControlLabel
                value="1"
                label="No"
                control={
                  <Radio name="homeless" color="primary" inputRef={register} />
                }
              />
            </RadioGroup>
          </Box>
        </Grid>
        {watch('homeless') === '0' && (
          <>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="streetAddress"
                label="Street Address"
                variant="outlined"
                inputRef={register}
                error={isError('streetAddress')}
                helperText={getFieldError('streetAddress')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="apartment"
                label="Apartment/Suite"
                variant="outlined"
                inputRef={register}
                error={isError('apartment')}
                helperText={getFieldError('apartment')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                name="city"
                label="City/Town"
                variant="outlined"
                inputRef={register}
                error={isError('city')}
                helperText={getFieldError('city')}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                fullWidth
                name="state"
                label="State/Province"
                variant="outlined"
                inputRef={register}
                error={isError('state')}
                helperText={getFieldError('state')}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                fullWidth
                name="zipCode"
                label="Zip/Postal Code"
                variant="outlined"
                inputRef={register}
                error={isError('zipCode')}
                helperText={getFieldError('zipCode')}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                name="county"
                label="County"
                variant="outlined"
                inputRef={register}
                error={isError('county')}
                helperText={getFieldError('county')}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Box marginTop={2}>
            <InputLabel>
              <strong>Contact Numbers *</strong>&nbsp;
              <small>(at least one is required)</small>
            </InputLabel>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            type="tel"
            name="homePhone"
            label="Home Phone"
            variant="outlined"
            inputRef={register}
            error={isError('homePhone')}
            helperText={getFieldError('homePhone')}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            type="tel"
            name="workPhone"
            label="Work Phone"
            variant="outlined"
            inputRef={register}
            error={isError('workPhone')}
            helperText={getFieldError('workPhone')}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            type="tel"
            name="cellPhone"
            label="Cell Phone"
            variant="outlined"
            inputRef={register}
            error={isError('cellPhone')}
            helperText={getFieldError('cellPhone')}
          />
        </Grid>
        <Grid item xs={12}>
          <Box marginTop={2}>
            <InputLabel>
              <strong>Household Information</strong>
            </InputLabel>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            required
            fullWidth
            min="1"
            max="100"
            type="number"
            variant="outlined"
            name="householdTotal"
            label="How many people in your household?"
            inputRef={register}
            error={isError('householdTotal')}
            helperText={getFieldError('householdTotal')}
          />
          <FormHelperText>Include yourself in the total</FormHelperText>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end">
        <Button
          fullWidth
          type="submit"
          color="primary"
          variant="contained"
          className={classes.submit}
          disabled={loading || !formState.dirty}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            submitButtonLabel
          )}
        </Button>
      </Grid>
    </form>
  );
};

Form.propTypes = {
  pantryPath: PropTypes.string,
  userDetails: UserDetailsType
};

Form.defaultProps = {
  pantryPath: null,
  userDetails: {}
};

export default Form;
