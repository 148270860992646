import React from 'react';
import styled from 'styled-components';
import { Chip } from '@material-ui/core';
import IconsByStatus from '../../constants/ReservationStatusIcons';
import ColorsByStatus from '../../constants/ReservationStatusColors';
import LabelsByStatus from '../../constants/ReservationStatusLabels';

export default styled(({ status, ...props }) => (
  <Chip
    variant="outlined"
    icon={IconsByStatus[status]}
    color={ColorsByStatus[status]}
    label={LabelsByStatus[status]}
    {...props}
  />
))`
  padding: 18px;
  text-transform: uppercase;
`;
