import styled from 'styled-components';
import { withTheme } from '@material-ui/core';

export default withTheme(styled.hr`
  border: 0;
  outline: 0;
  opacity: 0.5;
  color: black;
  height: 1.5em;
  font-size: 16px;
  font-weight: 600;
  line-height: 1em;
  position: relative;
  text-align: center;
  &:before {
    left: 0;
    top: 50%;
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    background: linear-gradient(
      to right,
      transparent,
      ${({ theme: { palette } }) => palette.common.darkGray},
      transparent
    );
  }
  &:after {
    padding: 0 0.5em;
    position: relative;
    line-height: 1.5em;
    display: inline-block;
    content: attr(data-content);
    color: ${({ theme: { palette } }) => palette.common.darkGray};
    background-color: ${({ theme: { palette } }) => palette.common.white};
  }
`);
